import React from 'react'

export const EnglishIcon = () => (
  <svg xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 513 342" width="30" height="15">
    <path fill="#FFFFFF" d="M0 0h513v342H0z"></path>
    <path
      d="M0 0h513v26.3H0zm0 52.6h513v26.3H0zm0 52.6h513v26.3H0zm0 52.6h513v26.3H0zm0 52.7h513v26.3H0zm0 52.6h513v26.3H0zm0 52.6h513V342H0z"
      fill="#D80027"
    ></path>
    <path fill="#2E52B2" d="M0 0h256.5v184.1H0z"></path>
    <path
      d="m47.8 138.9-4-12.8-4.4 12.8H26.2l10.7 7.7-4 12.8 10.9-7.9 10.6 7.9-4.1-12.8 10.9-7.7zm56.3 0-4.1-12.8-4.2 12.8H82.6l10.7 7.7-4 12.8 10.7-7.9 10.8 7.9-4-12.8 10.7-7.7zm56.5 0-4.3-12.8-4 12.8h-13.5l11 7.7-4.2 12.8 10.7-7.9 11 7.9-4.2-12.8 10.7-7.7zm56.2 0-4-12.8-4.2 12.8h-13.3l10.8 7.7-4 12.8 10.7-7.9 10.8 7.9-4.3-12.8 11-7.7zM100 75.3l-4.2 12.8H82.6L93.3 96l-4 12.6 10.7-7.8 10.8 7.8-4-12.6 10.7-7.9h-13.4zm-56.2 0-4.4 12.8H26.2L36.9 96l-4 12.6 10.9-7.8 10.6 7.8L50.3 96l10.9-7.9H47.8zm112.5 0-4 12.8h-13.5l11 7.9-4.2 12.6 10.7-7.8 11 7.8-4.2-12.6 10.7-7.9h-13.2zm56.5 0-4.2 12.8h-13.3l10.8 7.9-4 12.6 10.7-7.8 10.8 7.8-4.3-12.6 11-7.9h-13.5zm-169-50.6-4.4 12.6H26.2l10.7 7.9-4 12.7L43.8 50l10.6 7.9-4.1-12.7 10.9-7.9H47.8zm56.2 0-4.2 12.6H82.6l10.7 7.9-4 12.7L100 50l10.8 7.9-4-12.7 10.7-7.9h-13.4zm56.3 0-4 12.6h-13.5l11 7.9-4.2 12.7 10.7-7.9 11 7.9-4.2-12.7 10.7-7.9h-13.2zm56.5 0-4.2 12.6h-13.3l10.8 7.9-4 12.7 10.7-7.9 10.8 7.9-4.3-12.7 11-7.9h-13.5z"
      fill="#FFFFFF"
    ></path>
  </svg>
)

export const ChineseIcon = () => (
  <svg xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 513 342" width="30" height="15">
    <path fill="#D80027" d="M0 0h513v342H0z"></path>
    <path
      d="m226.8 239.2-9.7-15.6-17.9 4.4 11.9-14.1-9.7-15.6 17.1 6.9 11.8-14.1-1.3 18.4 17.1 6.9-17.9 4.4zM290.6 82l-10.1 15.4 11.6 14.3-17.7-4.8-10.1 15.5-1-18.4-17.7-4.8 17.2-6.6-1-18.4 11.6 14.3zm-54.4-56.6-2 18.3 16.8 7.6-18 3.8-2 18.3-9.2-16-17.9 3.8 12.3-13.7-9.2-15.9 16.8 7.5zm56.6 136.4-14.9 10.9 5.8 17.5-14.9-10.8-14.9 11 5.6-17.6-14.9-10.7 18.4-.1 5.6-17.6 5.8 17.5zM115 46.3l17.3 53.5h56.2l-45.4 32.9 17.3 53.5-45.4-33-45.5 33 17.4-53.5-45.5-32.9h56.3z"
      fill="#FFDA44"
    ></path>
  </svg>
)

export const HindiIcon = () => (
  <svg xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 513 342" width="30" height="15">
    <path fill="#FFFFFF" d="M0 0h512v342H0z"></path>
    <path fill="#338AF3" d="M0 0h512v114H0zm0 228h512v114H0z"></path>
    <circle fill="#FFDA44" stroke="#D6AB00" strokeWidth="5px" cx="256.5" cy="171" r="40"></circle>
  </svg>
)

export const PortugueseIcon = () => (
  <svg xmlns="http://www.w3.org/1999/xlink" viewBox="0 85.333 512 341.333" width="30" height="15">
    <path fill="#D80027" d="M0 85.337h512v341.326H0z"></path>
    <path fill="#6DA544" d="M196.641 85.337v341.326H0V85.337z"></path>
    <circle fill="#FFDA44" cx="196.641" cy="256" r="64"></circle>
    <path fill="#D80027" d="M160.638 224v40.001c0 19.882 16.118 36 36 36s36-16.118 36-36V224h-72z"></path>
    <path fill="#FFFFFF" d="M196.638 276c-6.617 0-12-5.383-12-12v-16h24.001v16c-.001 6.616-5.385 12-12.001 12z"></path>
  </svg>
)

export const KoreanIcon = () => (
  <svg xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 900 600" width="30" height="15">
    <path fill="#FFFFFF" d="M0 0h900v600H0z"></path>
    <g transform="rotate(-56.31)">
      <path
        d="M-75 228.3H75m-150 37.5H75m-150 37.5H75m-150 475H75m-150 37.5H75m-150 37.5H75"
        stroke="#000000"
        strokeWidth="25px"
      ></path>
      <path stroke="#FFFFFF" strokeWidth="12.5px" d="M0 753.3v125"></path>
      <circle fill="#CA163A" cy="540.8" r="150"></circle>
      <path
        fill="#0E4896"
        d="M0 390.8c-41.4 0-75 33.6-75 75s33.6 75 75 75 75 33.6 75 75-33.6 75-75 75c-82.8 0-150-67.2-150-150s67.2-150 150-150z"
      ></path>
    </g>
    <path
      d="m231.56 535.73-83.205-124.808M262.76 514.928l-83.205-124.807m114.407 104.006-83.205-124.808m478.43-138.675-83.205-124.807M720.39 209.843 637.184 85.036m114.407 104.006L668.386 64.234"
      stroke="#000000"
      strokeWidth="25px"
    ></path>
    <path
      stroke="#FFFFFF"
      strokeWidth="12.5px"
      d="m205.6 462.897 31.202-20.8m389.981-259.989 36.444-24.296m31.202-20.801 31.202-20.801"
    ></path>
  </svg>
)

export const ArabicIcon = () => (
  <svg xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 513 342" width="30" height="15">
    <path fill="#FFFFFF" d="M0 0h513v342H0z"></path>
    <path fill="#EE0000" d="M0 0h513v171H0z"></path>
  </svg>
)

export const EspanolIcon = () => (
  <svg xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 22.5 15" width="24" height="16">
    <path fill="#FFFFFF" d="M0 0h22.5v15H0V0z"></path>
    <path fill="#D03433" d="M0 0h22.5v4H0V0zm0 11h22.5v4H0v-4z"></path>
    <path fill="#FBCA46" d="M0 4h22.5v7H0V4z"></path>
    <path fill="#FFFFFF" d="M7.8 7h1v.5h-1V7z"></path>
    <path
      fill="#A41517"
      d="M7.2 8.5c0 .3.3.5.6.5s.6-.2.6-.5L8.5 7H7.1l.1 1.5zM6.6 7c0-.3.2-.5.4-.5h1.5c.3 0 .5.2.5.4V7l-.1 1.5c-.1.6-.5 1-1.1 1-.6 0-1-.4-1.1-1L6.6 7z"
    ></path>
    <path
      fill="#A41517"
      d="M6.8 7.5h2V8h-.5l-.5 1-.5-1h-.5v-.5zM5.3 6h1v3.5h-1V6zm4 0h1v3.5h-1V6zm-2.5-.5c0-.3.2-.5.5-.5h1c.3 0 .5.2.5.5v.2c0 .2-.1.3-.3.3H7c-.1 0-.2-.1-.2-.2v-.3z"
    ></path>
  </svg>
)
