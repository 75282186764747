import { Box, Flex, Image, Spinner, Table, TableContainer, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import useCustomTranslation from '../../hooks/useCustomTranslation'

const BettingTable = ({ columns, datasource, isLoading, rowClick = () => {}, height = '282px' }: any) => {
  const { t } = useCustomTranslation()
  return (
    <>
      <Box
        overflowY="auto"
        maxHeight={height}
        borderBottomEndRadius={datasource.length > 0 ? 12 : 0}
        borderBottomStartRadius={datasource.length > 0 ? 12 : 0}
        overflow={'hidden'}
      >
        <TableContainer>
          <Table variant="simple" size="sm" background={'#18181b'}>
            <Thead
              background={'#18181b'}
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}
            >
              <Tr>
                {columns.map(({ key, title, headerStyle, flexWrapper = { justifyContent: 'center' } }) => (
                  <Th key={key} fontWeight={500} borderBottom="solid 1px #333" {...headerStyle}>
                    <Flex {...flexWrapper}>{title}</Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <motion.tbody
              key={datasource.length}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {datasource.map((row: any, index: number) => (
                <Tr key={row.id || index} onClick={rowClick}>
                  {columns.map((column: any) => {
                    const { rowStyle = { px: 3 }, dataIndex, render } = column
                    return (
                      <Td
                        key={column.key}
                        textAlign={'center'}
                        align="center"
                        borderBottom="solid 1px #2d2d32"
                        fontSize={14}
                        {...rowStyle}
                      >
                        {render ? render(row) : row[dataIndex]}
                      </Td>
                    )
                  })}
                </Tr>
              ))}
            </motion.tbody>
            {/* {!isLoading && !!latestData.length && (
              <Tbody ref={tableBodyRef} background={'#18181b'} fontSize={14}>
                {latestData.map((row: any, index: number) => (
                  <Tr key={row.id || index} onClick={rowClick}>
                    {columns.map((column: any) => {
                      const { rowStyle = { px: 3 }, dataIndex, render } = column
                      return (
                        <Td
                          key={column.key}
                          textAlign={'center'}
                          align="center"
                          borderBottom="solid 1px #2d2d32"
                          fontSize={14}
                          {...rowStyle}
                        >
                          {render ? render(row) : row[dataIndex]}
                        </Td>
                      )
                    })}
                  </Tr>
                ))}
              </Tbody>
            )} */}
          </Table>
        </TableContainer>
      </Box>

      {isLoading && (
        <Flex alignItems="center" justifyContent="center" w="100%" h="123px">
          <Spinner size="lg" />
        </Flex>
      )}

      {!datasource?.length && !isLoading && (
        <Flex
          w="100%"
          height="135px"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          color="#3d3d43"
          background={'#18181b'}
          borderBottomEndRadius={12}
          borderBottomStartRadius={12}
        >
          <Box>
            <Image w={'40px'} src="/images/norecord.webp" />
          </Box>
          <Box color={'#4b4b4d'} fontSize={'13px'}>
            {t('notification.youHavenorecordsYet')}
          </Box>
        </Flex>
      )}
    </>
  )
}

export default BettingTable
