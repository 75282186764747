import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { useAppSelector } from '@store/index.ts'
import { selectCurrentLotteryResultHash } from '@store/modules/game.slice'
import React, { ReactNode } from 'react'
import { TfiMenuAlt } from 'react-icons/tfi'
import { bgColorApp } from '../../const/color.ts'
import useCustomTranslation from '../../hooks/useCustomTranslation.tsx'
import { CopyBtn } from '../common/CopyBtn.tsx'
import SelectMenuPopup from '../UI/SelectMenuPopup'
import GamePlayAndVerification from './infos/GamePlayAndVerification'
import BottomPopup from './infos/GuidePlayPopup'
import ListResult from './ListRecord'
import RuleGame from './RuleGame.tsx'
import SoundSetting from './settings/SoundSetting'

interface Option {
  value: string
  label: string
  headingPopup?: string
  content: ReactNode
}

export default function ControlSection() {
  const { t } = useCustomTranslation()
  const currentLotteryResultHash = useAppSelector(selectCurrentLotteryResultHash)
  const sortLotteryResultHash = getShortHash(currentLotteryResultHash || '')
  const options1: Option[] = [
    {
      value: 'BettingRecord',
      label: 'bettingRecord',
      headingPopup: 'bettingListing',
      content: (
        <Box p={4}>
          <ListResult noPaging={false} />
        </Box>
      ),
    },
    { value: 'SoundSetting', label: 'soundSetting', content: <SoundSetting /> },
    {
      value: 'Gameplay',
      label: 'gamePlay',
      content: <GamePlayAndVerification />,
    },
    {
      value: 'Verification',
      label: 'verification',
      content: <GamePlayAndVerification />,
    },
    {
      value: 'RuleGame',
      label: 'ruleGame',
      content: <RuleGame />,
    },
  ]

  return (
    <Stack
      p={2}
      borderRadius={8}
      background={bgColorApp}
      backgroundSize="cover"
      backgroundPosition="top center"
      backgroundImage="/images/bgBeehive.png"
      textAlign="center"
    >
      <Flex gap={1} mt={-1} alignItems="center" justifyContent="space-between">
        <BottomPopup />
        <Flex gap={2} justifyContent="center" alignItems="center" textAlign="center">
          <Text fontWeight={700} fontSize={'16px'}>
            {sortLotteryResultHash}
          </Text>
          <CopyBtn text={currentLotteryResultHash ?? ''} colorClassName="text-[#891333]" />
        </Flex>
        <SelectMenuPopup options={options1} icon={<TfiMenuAlt color="#891333" fontSize={22} />} />
      </Flex>
      <Text fontSize={'11px'} mt={-4} fontWeight={400} color="#D83467">
        {t('ourBureauPrizeHash')}
      </Text>
    </Stack>
  )
}
function getShortHash(text: string) {
  if (!text) return ''
  const length = text.length
  if (length <= 20) return text
  return `${text.slice(0, 10)}...${text.slice(length - 8, length)}`
}
