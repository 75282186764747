export const errors = {
  ErrCurrencyNotSupported: 'ErrCurrencyNotSupported',
}
export class AppError extends Error {
  code: string
  data: any
  constructor(code: string, message: string, data?: any) {
    super(message)
    this.code = code
    this.message = message
    this.data = data
  }
}
