import { BetType } from '@store/entities/order.entity.ts'
import React from 'react'
import useCustomTranslation from '../../hooks/useCustomTranslation.tsx'
import { TrendItem } from '../../utils/trends.ts'

type ChessProps = {
  type?: BetType | TrendItem | string
  width?: number | string
  height?: number | string
  style?: React.CSSProperties
}

const Chess = (props: ChessProps) => {
  const { type, width = '16px', height = '16px', style } = props
  const { i18n } = useCustomTranslation()

  const folderName = i18n.language === 'zh' ? 'zh' : 'en'
  const chessType = (type || '').toLowerCase()
  const defaultStyles = {
    width: width,
    height: height,
    minWidth: width,
    minHeight: height,
    display: 'inline-block',
  }

  if (!type) return <></>

  return <img src={`/images/chess/${folderName}/${chessType}.svg`} alt="" style={{ ...defaultStyles, ...style }} />
}

export default Chess
