import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import gameSlice from '@/redux/store/modules/game.slice'
import orderSlice from '@/redux/store/modules/order.slice'
import React, { useEffect } from 'react'

export default function NewGameSubscription() {
  const dispatch = useAppDispatch()
  const gameId = useGameId()

  const _lastGameMessage = useSubscription(Topics.lastGame(gameId))
  const lastGameMessage = _lastGameMessage?.message?.message

  useEffect(() => {
    if (!lastGameMessage) return
    try {
      const isRetain = _lastGameMessage?.message?.config?.retain || false
      const data = JSON.parse(lastGameMessage.toString() || '')
      dispatch(orderSlice.actions.lastGameFinished(data))
      dispatch(gameSlice.actions.lastGameUpdated({ ...data, isRetain }))
    } catch (error) {
      console.warn('configUpdated error: ', error)
    }
  }, [lastGameMessage])

  return <></>
}
