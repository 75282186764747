import useGameId from '@/hooks/useGameId'
import { BroadcastChannel } from 'broadcast-channel'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function SyncBetweenTabs() {
  const gameId = useGameId()
  const navigate = useNavigate()
  useEffect(() => {
    const channel = new BroadcastChannel('auth_channel')
    channel?.postMessage('logout')
    channel.onmessage = (event: string) => {
      if (event == 'logout') {
        navigate(`/${gameId}/error`, { replace: true })
      }
    }

    return () => {
      channel.close()
    }
  }, [])

  return <></>
}
