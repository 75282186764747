import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import { TopWinner } from '@/redux/store/entities/game.entity'
import gameSlice from '@/redux/store/modules/game.slice'
import React, { useEffect } from 'react'

export default function TopWinnerSubscription() {
  const dispatch = useAppDispatch()

  const _topWinnerMessage = useSubscription(Topics.topReward)
  const topWinnerMessage = _topWinnerMessage?.message?.message

  useEffect(() => {
    if (!topWinnerMessage) return
    try {
      const winners = JSON.parse(topWinnerMessage.toString() || '') as TopWinner
      const addGameIdToWinner = winners.winners.map((item) => ({
        ...item,
        bet: winners.game.result,
        gameID: winners.game.gameId,
      }))
      const res = { createdAt: winners.createdAt, game: winners.game, winners: addGameIdToWinner }
      dispatch(gameSlice.actions.topWinnerUpdated(res))
    } catch (error) {
      console.warn('TopWinnerSubscription error: ', error)
    }
  }, [topWinnerMessage])

  return <></>
}
