import { Button, Heading, Stack, Text } from '@chakra-ui/react'
import useCustomTranslation from '@hooks/useCustomTranslation'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { IframeCommunicationEvent, iframeCon, isLoadedByIframe } from '../utils/iframe'

export default function Error() {
  const { t } = useCustomTranslation()
  const currentPath = window.location.pathname
  const game_id = currentPath.split('/')[1]
  const { state } = useLocation()
  const { message } = state || {}

  const handleReturn = () => {
    if (isLoadedByIframe()) {
      iframeCon.sendToParent({ event: IframeCommunicationEvent.GO_BACK, params: {} })
      return
    }

    window.close()
  }

  return (
    <Stack height="100vh" alignItems="center" p={8} textAlign="center" justifyContent="center">
      <Heading color="white">{t(game_id)}</Heading>

      <Text color="white" m={4}>
        {message ? message : t(`notification.ErrSessionInvalid`)}
      </Text>

      <Button aria-label="returnButton" aria-labelledby="returnButton" onClick={handleReturn}>
        {t('returnButton')}
      </Button>
    </Stack>
  )
}
