import { Button, Stack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { LuFileQuestion } from 'react-icons/lu'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppDrawer } from '../../common/AppDrawer.tsx'
import GamePlayAndVerification from './GamePlayAndVerification'

const BottomPopup = () => {
  const [popup, setPopup] = useState('')
  const navigate = useNavigate()
  const location = useLocation()

  const closePopup = () => {
    setPopup('')
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('param')
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true })
  }
  return (
    <Stack ml={-2}>
      <Button
        aria-label="AppDrawer"
        aria-labelledby="AppDrawer"
        m={0}
        p={0}
        background="none"
        _hover={{ background: 'none' }}
        onClick={() => {
          setPopup('Gameplay')
          const searchParams = new URLSearchParams(location.search)
          searchParams.set('param', 'Gameplay')
          navigate(`?${searchParams.toString()}`, { replace: true })
        }}
      >
        <LuFileQuestion color="#891333" />
      </Button>

      <AppDrawer isOpen={'Gameplay' === popup} onClose={closePopup} overlay={true}>
        <GamePlayAndVerification />
      </AppDrawer>
    </Stack>
  )
}

export default BottomPopup
