import useGameId from '@/hooks/useGameId'
import { isLastGameLose, isLastGameWinner } from '@/redux/store/common/selectors'
import { Box, Flex, Image, Stack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { selectIsLogged } from '@store/modules/auth.slice'
import { refreshMyOrders, selectTop10MyOrders } from '@store/modules/order.slice'
import 'rc-pagination/assets/index.css'
import React, { useEffect } from 'react'
import useCustomTranslation from '../../hooks/useCustomTranslation'
import BetDetail from './infos/BetDetail'
export default function ListRecordHomePage() {
  const dispatch = useAppDispatch()
  const orderHistory = useAppSelector(selectTop10MyOrders)
  const isLogged = useAppSelector(selectIsLogged)
  const gameId = useGameId()
  const { t } = useCustomTranslation()
  const isWinner = useAppSelector(isLastGameWinner)
  const isLoser = useAppSelector(isLastGameLose)
  useEffect(() => {
    if (isLogged && gameId) {
      dispatch(refreshMyOrders({ gameId }))
    }
  }, [isLogged, gameId, isWinner, isLoser])
  return (
    <Stack gap="9.5px">
      {orderHistory?.length > 0 ? (
        orderHistory.map((id) => <BetDetail key={id} id={id} />)
      ) : (
        <Flex
          w="100%"
          height="200px"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          color="#3d3d43"
          gap={'11px'}
        >
          <Box>
            <Image w={'40px'} src="/images/norecord.webp" />
          </Box>
          <Box color={'#4b4b4d'} fontSize={'13px'}>
            {t('notification.youHavenorecordsYet')}
          </Box>
        </Flex>
      )}
    </Stack>
  )
}
