import { Container, Flex, FormControl, FormLabel, Stack, Switch, Text } from '@chakra-ui/react'
import { RootState, useAppDispatch, useAppSelector } from '@store'
import { toggleSound } from '@store/modules/audio.slice'
import React from 'react'
import useCustomTranslation from '../../../hooks/useCustomTranslation'

export default function SoundSetting() {
  const dispatch = useAppDispatch()
  const { t } = useCustomTranslation()
  const isMuted = useAppSelector((state: RootState) => state.sound.isMuted)
  const handleToggleSound = (e: any) => {
    dispatch(toggleSound(e.target.checked))
  }
  return (
    <Container>
      <Stack color="white" padding={6} gap={6}>
        <Text fontWeight={700}>{t('setting.audioSetting')}</Text>
        <Flex color="white">
          <Stack>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="email-alerts" mb="0">
                {t('setting.music')}
              </FormLabel>
              <Switch isChecked={isMuted} onChange={handleToggleSound} id="email-alerts" />
            </FormControl>
          </Stack>
        </Flex>
      </Stack>
    </Container>
  )
}
