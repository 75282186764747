import useGameId from '@/hooks/useGameId.ts'
import { selectBetAmountByGame } from '@/redux/store/modules/preference.slice.ts'
import { Box, Button, Container, Flex, Input, InputGroup, InputLeftElement, Stack, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { setCoinPosition } from '@store/modules/animation.slice'
import { selectGameConfigs } from '@store/modules/game.slice'
import orderSlice, { selectCreateOrderStatus, selectOrderAmount, selectOrderBetType } from '@store/modules/order.slice'
import { selectWallet } from '@store/modules/wallet.slice.ts'
import { gsap } from 'gsap'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { bgColorApp, bgInput, textGray, textGrayWhite } from '../../const/color'
import { currency } from '../../const/currency'
import { formatNumberInput } from '../../const/format.ts'
import { gameType } from '../../const/game-type.ts'
import useCustomTranslation from '../../hooks/useCustomTranslation.tsx'
import ButtonBetting from '../UI/ButtonBetting'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import Tween = gsap.core.Tween

function Denominations({ valueBet }: { valueBet: [string, Dispatch<SetStateAction<string>>] }) {
  const [inputValue, setInputValue] = valueBet
  const amount = useAppSelector(selectOrderAmount)
  const config = useAppSelector(selectGameConfigs)
  const amountButtons = config.betAmounts
    ? config.betAmounts.split(',').map(Number)
    : [10, 50, 100, 200, 500, 1000, 10000]
  const audioRef: any = useRef<HTMLAudioElement | null>(null)
  const handleIncrement = (increment: any) => {
    if (audioRef.current) {
      audioRef.current.play()
    }
    setInputValue((prev: any) => {
      const newValue = Number(prev) + increment
      return newValue <= config.maxAmount ? newValue : prev
    })
  }

  return (
    <Flex
      flexWrap="nowrap"
      overflowX="auto"
      gap={2}
      marginBottom={4}
      marginTop="13px"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '-ms-overflow-style': 'none', // IE and Edge
        'scrollbar-width': 'none', // Firefox
      }}
    >
      <audio autoPlay ref={audioRef} src="/sound/click.mp3" />
      {amountButtons.map((_amount) => (
        <Button
          aria-label="Amount"
          aria-labelledby="Amount"
          key={_amount}
          height={'30px'}
          minWidth="70px"
          fontSize="1rem"
          backgroundColor={bgColorApp}
          borderRadius={8}
          color={textGray}
          transition="0.2s all"
          fontWeight={500}
          isActive={amount === _amount}
          _hover={{ backgroundColor: '#29292f', color: '#fff' }}
          _active={{
            backgroundColor: '#4e4f50',
            color: '#b2fc00',
            textShadow: '0px 0px 10px #b2fc00',
            fontWeight: 600,
          }}
          // onClick={() => dispatch(orderSlice.actions.amountUpdated(_amount))}
          onClick={() => handleIncrement(_amount)}
          lineHeight={1}
        >
          <Box
            as="span"
            // position="relative"
            // bottom="-1px"
          >
            {_amount}
          </Box>
        </Button>
      ))}
    </Flex>
  )
}

const createParticleEffect = (typeBet: string, currency: string = 'USDT') => {
  let appear
  if (typeBet === 'BANKER') {
    appear = 0
  } else if (typeBet === 'TIE') {
    appear = 1
  } else {
    appear = 2
  }
  const type = document.querySelector(`.type${appear}`)
  if (!type) {
    console.warn('No element with the class ".type" found.')
    return
  }
  const particleContainer = document.createElement('div')
  particleContainer.style.position = 'absolute'
  particleContainer.style.top = '0'
  particleContainer.style.left = '50%'
  particleContainer.style.transform = 'translate(-50%, -50%)'
  particleContainer.style.pointerEvents = 'none'
  particleContainer.style.zIndex = '1000'
  type.appendChild(particleContainer)

  for (let i = 0; i < 30; i++) {
    const particle = document.createElement('div')
    const currencyIcon = document.createElement('img')
    currencyIcon.src = `/images/currencies/${currency}.svg`
    currencyIcon.style.width = '20px'
    currencyIcon.style.height = '20px'
    particle.style.width = '20px'
    particle.style.height = '20px'
    // particle.innerHTML = `${currency}`
    particle.append(currencyIcon)
    particle.style.position = 'absolute'
    particle.style.fontSize = '12px'
    particleContainer.appendChild(particle)
    gsap.to(particle, {
      x: (Math.random() - 0.5) * 80,
      y: -(Math.random() - 0.4) * 60,
      opacity: 0,
      scale: 0.5,
      duration: 1,
      ease: 'expoScale(0.2,2,none)',
      onComplete: () => particle.remove(),
    })
  }

  setTimeout(() => particleContainer.remove(), 600)
}

export default function BettingHBP() {
  const { t } = useCustomTranslation()
  const amount = useAppSelector(selectOrderAmount)
  const configRule = useAppSelector(selectGameConfigs)
  const totalCoin = 5
  //input nhập tiền để betting
  const [inputValue, setInputValue] = useState(amount + '')
  const dispatch = useAppDispatch()
  // const balance = useAppSelector((state: RootState) => state.balance)
  const wallet = useAppSelector(selectWallet)
  const gameID = useGameId()
  const audioRef: any = useRef<HTMLAudioElement | null>(null)
  const createOrderStatus = useAppSelector(selectCreateOrderStatus)
  const currentAnimation = useRef<Tween | null>(null)
  const [particleTrigger, setParticleTrigger] = useState(false)

  const betType = useAppSelector(selectOrderBetType)
  const coins = useRef<(HTMLDivElement | null)[]>([])
  const betAmount = useAppSelector(selectBetAmountByGame(gameID))

  useEffect(() => {
    if (betAmount) {
      setInputValue(betAmount + '')
    }
  }, [])

  const handleChange = (e: any) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '')
    const value = parseInt(inputValue) || 0
    let betAmountValue = 0
    if (value >= configRule.maxAmount) {
      betAmountValue = configRule.maxAmount
    } else {
      betAmountValue = value
    }
    setInputValue(betAmountValue.toString())
  }
  useEffect(() => {
    if (amount != (parseInt(inputValue) || 0)) {
      setInputValue(amount + '')
    }
  }, [amount])

  useEffect(() => {
    dispatch(orderSlice.actions.amountUpdated(Number(inputValue)))
  }, [inputValue])

  useEffect(() => {
    if (coins.current[0]) {
      const rect = coins.current[0].getBoundingClientRect()
      dispatch(setCoinPosition({ x: rect.left, y: rect.top }))
    }
  }, [dispatch])

  useEffect(() => {
    if (createOrderStatus === 'succeeded') {
      if (currentAnimation.current) {
        currentAnimation.current.kill()
      }
      setParticleTrigger(true)
      setTimeout(() => setParticleTrigger(false), 2000)
    }
  }, [createOrderStatus])

  // const element = document.querySelector('.type0')

  const getSetupValues = () => {
    const game = gameType.find((item) => item.id === gameID)
    const type = game?.type.find((c) => c === betType) || 'BIG'
    const id = `bet-type-btn-${type}`

    const betTypeBtn = document.getElementById(id)
    const betTypeRect = betTypeBtn?.getBoundingClientRect()

    let targetX
    let targetY

    if (betTypeRect) {
      targetX = betTypeRect.left + betTypeRect.width / 2 + window.scrollX
      targetY = betTypeRect.top + betTypeRect.height / 4 + window.scrollY
    }

    return {
      targetX,
      targetY,
      game,
      type,
    }
  }

  // const [vitri, setVitri] = useState({
  //   x: getSetupValues().targetX,
  //   y: getSetupValues().targetY,
  // })
  // useEffect(() => {
  //   // const element = document.querySelector('.type0')
  //   // if (element) {
  //   //   const rect = element.getBoundingClientRect()
  //   //   setVitri({
  //   //     x: rect.x + window.scrollX,
  //   //     y: rect.y + window.scrollY,
  //   //     width: rect.width,
  //   //     height: rect.height,
  //   //   })
  //   // }
  // }, [element])

  // const updatePosition = () => {
  //   const game = gameType.find((item) => item.id === gameID)
  //   const type = game?.type.find((c) => c === betType) || 'BIG'
  //   let classSelect
  //   console.log(type)
  //   if (type === 'BANKER') {
  //     classSelect = '.type0'
  //   } else if (type === 'TIE') {
  //     classSelect = '.type1'
  //   } else {
  //     classSelect = '.type2'
  //   }
  //
  //   const element = document.querySelector(classSelect)
  //   if (element) {
  //     const rect = element.getBoundingClientRect()
  //     setVitri({
  //       x: rect.x + window.scrollX,
  //       y: rect.y + window.scrollY,
  //       width: rect.width,
  //       height: rect.height,
  //     })
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', updatePosition)
  //   window.addEventListener('resize', updatePosition) // Cập nhật khi thay đổi kích thước cửa sổ
  //
  //   updatePosition()
  //
  //   return () => {
  //     window.removeEventListener('scroll', updatePosition)
  //     window.removeEventListener('resize', updatePosition)
  //   }
  // }, [betType])

  useEffect(() => {
    if (!particleTrigger) return

    const coinsArray = coins.current

    if (coinsArray.length <= 0) return

    const { targetX, targetY, type } = getSetupValues()

    if (!targetX || !targetY) return

    const animateCoin = async (coinElement: any) => {
      if (!coinElement) return

      const coinElementRect = coinElement.getBoundingClientRect()
      const coinElementX = coinElementRect.left
      const coinElementY = coinElementRect.top + window.scrollY
      const deltaX = targetX - coinElementX
      const deltaY = targetY - coinElementY

      currentAnimation.current = gsap.to(coinElement, {
        x: deltaX,
        y: deltaY,
        duration: 1.2,
        ease: 'power2.out',
        opacity: 0.4,
        rotate: '360deg',
        scale: 1.1,
        onComplete: () => {
          currentAnimation.current = null
        },
      })
    };

    (async () => {
      for (let i = 0; i < totalCoin; i++) {
        const coinElement = coinsArray[i]
        await animateCoin(coinElement)
        await new Promise((resolve) => setTimeout(resolve, 300))
      }
    })();

    setTimeout(async () => {
      for (let i = 0; i < totalCoin; i++) {
        createParticleEffect(type, wallet.currency)
        await new Promise((resolve) => setTimeout(resolve, 300))
      }
    }, 300)
  }, [particleTrigger])

  return (
    <Container>
      <audio autoPlay ref={audioRef} src="/sound/click.mp3" />
      <Flex position="relative" alignItems="center" justifyContent="space-between">
        <Stack w="45%" gap="10px" fontWeight={400}>
          <Flex alignItems="baseline">
            <Text color={textGray} fontSize={'12px'} letterSpacing="0.4px">
              {t('bettingLimits')}:
            </Text>
            <Text marginInline={2} color={textGrayWhite} fontWeight={500} fontSize="13px">
              {configRule.minAmount} ~ {formatNumberNoDecimal(configRule.maxAmount)}
            </Text>
          </Flex>
          <Flex alignItems="baseline" mt={'-12px'}>
            <Text color={textGray} fontSize={'12px'} letterSpacing="0.4px">
              {t('maximumWin')}:{' '}
            </Text>
            <Text marginInline={2} color={textGrayWhite} fontWeight={500} fontSize="13px">
              {formatNumber(amount * (betType !== 'TIE' ? configRule.winRate : (configRule.tieRate ?? 1)))}
            </Text>
          </Flex>
          {/* <Flex>
            <Flex mt={-2}>
              <Text color={textGray}>{t('winRate')}: </Text>
              <Text marginInline={2} color={textGrayWhite} textStyle="semibold">
                {configRule.winRate}
              </Text>
            </Flex>
            {configRule.tieRate && (
              <Flex mt={-2}>
                <Text color={textGray}>{t('winRate')}: </Text>
                <Text marginInline={2} color={textGrayWhite} textStyle="semibold">
                  {configRule.tieRate}
                </Text>
              </Flex>
            )}
          </Flex> */}
        </Stack>

        <Stack w="50%">
          <Flex
            backgroundColor={bgInput}
            alignItems="center"
            justifyContent="space-between"
            p={1}
            border="1px solid #29292f"
            borderRadius={8}
            height="33px"
          >
            <button
              aria-label="Minus"
              aria-labelledby="Minus"
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current.play()
                }
                setInputValue((prev) => {
                  const currentValue = Number(prev)

                  const digitCount = currentValue.toString().length

                  const smallestNumber = 10 ** (digitCount - 1)

                  const munis = 10 ** (digitCount - 1) - 10 ** (digitCount - 2)
                  const largestNumberWithOneDigitLess = munis < configRule.minAmount ? configRule.minAmount : munis

                  const newValue = Math.max(smallestNumber - 10 ** (digitCount - 1), currentValue - smallestNumber)

                  const resultValue =
                    newValue < largestNumberWithOneDigitLess ? largestNumberWithOneDigitLess : newValue
                  return resultValue.toString()
                })
              }}
              style={{ padding: '3px', background: '#29292f', color: '#68686f', borderRadius: 4 }}
            >
              <FaMinus />
            </button>

            <InputGroup position="relative">
              <InputLeftElement top="50%" transform="translateY(-50%)">
                {currency.find((i) => i.name === wallet.currency)?.icon}
              </InputLeftElement>
              {particleTrigger &&
                Array(totalCoin)
                  .fill(null)
                  .map((i, idx) => (
                    <Stack
                      key={idx}
                      pointerEvents="none"
                      ref={(el: any) => (coins.current[idx] = el)}
                      position="absolute"
                      top="11px"
                      left="11px"
                      zIndex={1000}
                    >
                      {currency.find((i) => i.name === wallet.currency)?.icon}
                    </Stack>
                  ))}
              <Input
                max={configRule.maxAmount}
                value={formatNumberInput(inputValue)}
                onChange={handleChange}
                textAlign="center"
                placeholder="Bet Amount"
                _placeholder={{ fontWeight: 500, color: '#2e2e2e', fontSize: 12 }}
                type="text"
                fontSize={18}
                fontWeight={700}
                border="none"
                outline="none !important"
                boxShadow="none !important"
                position="relative"
                inputMode="decimal"
              />
            </InputGroup>
            <button
              aria-label="Add"
              aria-labelledby="Add"
              onClick={() => {
                setInputValue((prev) => {
                  if (audioRef.current) {
                    audioRef.current.play()
                  }
                  const increment = smallestSameDigitCount(Number(prev))
                  let newValue = Number(prev) + increment
                  if (newValue < 10) {
                    newValue = 10
                  }
                  return newValue <= configRule.maxAmount ? newValue.toString() : configRule.maxAmount.toString()
                })
              }}
              style={{ padding: 3, background: '#29292f', color: '#68686f', borderRadius: 4 }}
            >
              <FaPlus />
            </button>
          </Flex>
        </Stack>
      </Flex>

      <Denominations valueBet={[inputValue, setInputValue]} />
      <ButtonBetting />
    </Container>
  )
}

function smallestSameDigitCount(number: number) {
  // Chuyển số thành chuỗi để xác định số lượng chữ số
  const numStr = number.toString()

  // Lấy số lượng chữ số của số đã cho
  const digitCount = numStr.length

  // Tạo số nhỏ nhất có cùng số lượng chữ số
  const smallestNumber = Math.pow(10, digitCount - 1)
  return smallestNumber
}

export const formatNumberNoDecimal = (amount: number | undefined | string): string | 0 => {
  if (!amount) return 0
  const re = '\\d(?=(\\d{3})+$)'
  const rounding = Math.round(Number(amount))
  return `${rounding.toString().replace(new RegExp(re, 'g'), '$&,')}`
}
export const formatNumber = (amount: number | undefined | string): string | 0 => {
  if (!amount) return '0.00'

  const numberAmount = Number(amount)
  const roundedAmount = numberAmount.toFixed(2)

  const re = /(\d)(?=(\d{3})+\.)/g
  return roundedAmount.replace(re, '$1,')
}
