import Connector from '@/hooks/Connector.tsx'
import useGameId from '@/hooks/useGameId.ts'
import { Stack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { isLastGameWinner } from '@store/common/selectors'
import { login, selectIsLogged, selectUserId } from '@store/modules/auth.slice'
import gameSlice, { gameActions } from '@store/modules/game.slice'
import { refreshMyOrders } from '@store/modules/order.slice'
import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { bgColorApp } from '../const/color'
import { ErrorHandlerWrapper } from './common/ErrorHandlerWrapper.tsx'
import GameConfigSubscription from './common/GameConfigSubscription.tsx'
import NewGameSubscription from './common/NewGameSubscription.tsx'
import { SyncBetweenTabs } from './common/SyncBetweenTabs.tsx'
import TopWinnerSubscription from './common/TopWinnerSubscription.tsx'
import WalletBalanceSubscription from './common/WalletBallanceSubscription.tsx'
import Breadcrumb from './hash-game/Breadcrumb'
import Notification from './hash-game/Notification'
import Record from './hash-game/Record'
import Result from './hash-game/Result'
import ResultGame from './UI/popup-site/ResultAround'

export default function AuthGame({ children }: { children: ReactNode }) {
  const [showWinGame, setShowWinGame] = useState(false)
  const dispatch = useAppDispatch()
  const isLogged = useAppSelector(selectIsLogged)
  const userId = useAppSelector(selectUserId)
  const isWinner = useAppSelector(isLastGameWinner)
  const game_id = useGameId()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(gameSlice.actions.updateGameId(game_id))
  }, [game_id])

  useEffect(() => {
    if (isLogged) {
      dispatch(refreshMyOrders({ gameId: game_id }))
      dispatch(gameActions.getGameConfigs())
      dispatch(gameActions.getServerTime())
      dispatch(gameActions.getTrends())
    }
  }, [isLogged, userId])

  const [params] = useSearchParams()
  const agency_id = params.get('agency_id')
  const session_token = params.get('session_token')
  const access_token = params.get('access_token') || ''

  useEffect(() => {
    if (!agency_id || !session_token) return
    dispatch(login({ agency_id, session_token, access_token })).then((resp) => {
      if (resp.meta.requestStatus === 'fulfilled') {
        const newParams = new URLSearchParams(params)
        newParams.delete('agency_id')
        newParams.delete('session_token')
        newParams.delete('access_token')
        newParams.delete('currency')
        navigate(`?${newParams.toString()}`, { replace: true })
      }
    })
  }, [agency_id, session_token])

  useEffect(() => {
    if (isWinner) {
      setShowWinGame(true)
    }
  }, [isWinner])

  return (
    <Connector>
      <Stack w="100%" color="white" backgroundColor={bgColorApp} gap={3} position="relative">
        <NewGameSubscription />
        <TopWinnerSubscription />
        <GameConfigSubscription />
        <WalletBalanceSubscription />
        <ErrorHandlerWrapper />
        <SyncBetweenTabs />
        <Notification />
        {/* <NotificationPrize /> */}

        {showWinGame && (
          <ResultGame show={true} result={isWinner ? 'win' : 'lose'} onClose={() => setShowWinGame(false)} />
        )}
        <Breadcrumb />
        {children}
        <Result />
        <Record />
      </Stack>
    </Connector>
  )
}
