import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '..'

const animationSlice = createSlice({
  name: 'animation',
  initialState: {
    bigPosition: { x: 0, y: 0 },
    smallPosition: { x: 0, y: 0 },
    coinPosition: { x: 0, y: 0 },
  },
  reducers: {
    setBigPosition: (state, payload) => {
      state.bigPosition = payload.payload
    },
    setSmallPosition: (state, payload) => {
      state.smallPosition = payload.payload
    },
    setCoinPosition: (state, payload) => {
      state.coinPosition = payload.payload
    },
    setCoinPositions: (state, payload) => {
      state.coinPosition = payload.payload
    },
  },
})

export const selectBig = (state: RootState) => state.animation.bigPosition
export const selectSmall = (state: RootState) => state.animation.smallPosition
export const selectCoin = (state: RootState) => state.animation.coinPosition

export const { setBigPosition, setSmallPosition, setCoinPosition } = animationSlice.actions
export default animationSlice
