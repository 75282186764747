const endpoint = {
  LOGIN_VIA_API_HUB: '/wallet/v1/login',
  LOGOUT: '/auth/logout',
  PlayerWallet: '/wallet/v1/agency/player-wallet',
  WALLET: '/wallet/v1/account/wallet',
  LIST_WALLETS: '/wallet/v1/account/wallets',
  LIST_WALLETS_ENABLED: '/wallet/v1/currency/enable',
  PLAYER_INFO: '/wallet/v1/account',
}

export default endpoint
