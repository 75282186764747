import { OrderDetail } from '@store/entities/order.entity'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { BetDetailInfo } from '../../components/hash-game/infos/BetDetail'

const languageOptions = ['en', 'zh', 'hi', 'pt', 'ko', 'ar', 'es']

const orderDefault: OrderDetail = {
  id: '',
  lotteryResultHash: '',
  bet: '',
  amount: 0,
  createdAt: '',
  win_amount: 0,
}
function View() {
  const [params] = useSearchParams()
  const from = params.get('from')
  const lang = (params.get('lang') || '').toLowerCase()
  const { i18n } = useTranslation()
  const [order, setOrder] = useState(orderDefault)

  useEffect(() => {
    if (languageOptions.includes(lang)) {
      i18n.changeLanguage(lang)
    }

    window.addEventListener(
      'message',
      (e: MessageEvent) => {
        if (e.origin == from) {
          setOrder(e.data)
        }
      },
      false,
    )
  }, [])

  return (
    <BetDetailInfo order={order as OrderDetail} gameID={order?.game_type ?? 'H_BS'} currencyUsed={order?.currency} />
  )
}

export default View
