import useGameId from '@/hooks/useGameId'
import { Flex, Image, Stack, Text } from '@chakra-ui/react'
import { RootState, useAppSelector } from '@store'
import { selectOrderHistoryNoPaging } from '@store/modules/order.slice'
import React, { useEffect, useMemo, useRef } from 'react'
import { CountUp } from 'use-count-up'
import { GameUrlMap } from '../../../const/game-type'

const ResultGame = ({ show, onClose, result }: { show: boolean; onClose: () => void; result: string }) => {
  const orderHistory = useAppSelector(selectOrderHistoryNoPaging)
  const isMuted = useAppSelector((state: RootState) => state.sound.isMuted)
  const audioRef: any = useRef<HTMLAudioElement | null>(null)
  const gameId = useGameId()

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose()
      }, 4000)

      return () => clearTimeout(timer)
    }
  }, [show, onClose])

  useEffect(() => {
    if (audioRef.current) {
      if (isMuted) audioRef.current.play()
      else audioRef.current.pause()
    }
  }, [isMuted])

  const accumulatedWinAmount = useMemo(() => {
    if (!show) return 0

    let lastWiningLRH = ''
    if (gameId === GameUrlMap.H_NIUNIU) {
      const roundWinItems = orderHistory.filter((i) => {
        const win = i.is_win || i.bet === i.result
        if (win && !lastWiningLRH) lastWiningLRH = i.lotteryResultHash
        const isPrevRound = !lastWiningLRH || lastWiningLRH === i.lotteryResultHash
        return win && isPrevRound
      })

      return roundWinItems.reduce((acc, i) => acc + (i.win_amount + Number(i.return_amount) ?? 0), 0)
    } else {
      const roundWinItems = orderHistory.filter((i) => {
        const win = i.is_win || i.bet === i.result
        if (win && !lastWiningLRH) lastWiningLRH = i.lotteryResultHash
        const isPrevRound = !lastWiningLRH || lastWiningLRH === i.lotteryResultHash
        return win && isPrevRound
      })

      return roundWinItems.reduce((acc, i) => acc + (i.win_amount ?? 0), 0)
    }
  }, [show, orderHistory])

  if (result === 'win') {
    return (
      <Stack
        position="fixed"
        backgroundColor="rgba(0, 0, 0, 0.4)"
        zIndex={1000}
        p={4}
        top="1%"
        left="50%"
        transform="translateX(-50%)"
        gap={2}
        w="100%"
        className="!max-w-[var(--chakra-sizes-prose)]"
      >
        <Stack>
          <audio autoPlay ref={audioRef} src="/sound/mining.mp3" />
          <Image className="m-auto" w="500px" src="/images/win.gif" alt="" />
          <Flex background="rgba(0, 0, 0, 0.6)" marginTop={-30} justifyContent={'center'} alignItems="center" gap={2}>
            <Text fontSize={18} fontWeight={700}>
              Win
            </Text>

            <Text fontSize={24} fontWeight={700} color="#c7fb14">
              +
              <CountUp isCounting end={Number(accumulatedWinAmount.toFixed(2))} duration={2} />
            </Text>
          </Flex>
        </Stack>
      </Stack>
    )
  }
}

export default ResultGame
