import { Configs } from '@/const/configs'
import { ServiceConfig } from '@/redux/store/services/service-config'
import axios, { AxiosResponse } from 'axios'
import errorCode from '../const/error-code'
import { getInitialLang } from '../i18n'

const fetcher = axios.create({
  baseURL: Configs.walletApiUrl,
  withCredentials: false,
})

fetcher.interceptors.request.use(async (config: any) => {
  const token = ServiceConfig.token
  config.headers['x-app-os'] = 'web'
  config.headers['x-api-version'] = '2.0.48'
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    config.headers['x-access-token'] = `${token}`
    config.headers['osversion'] = '16.4'
  }
  const lng = getInitialLang()
  if (lng) {
    config.headers['language'] = lng.slice(0, 2)
  }
  return config
})

fetcher.interceptors.response.use(
  async (response: AxiosResponse<any>) => {
    return response
  },
  async (error: any) => {
    if (error.response?.status === errorCode.other.MAINTENANCE) {
      window.location.href = '/maintenance'
    }
    return Promise.reject(error)
  },
)

export const fetchFn = async <T = any>(
  method: Method,
  endpoint: string,
  data?: ConfigKey,
  extraConfig?: ConfigKey,
): Promise<AxiosResponse<Resp<T>>> => {
  const config: { params?: ConfigKey; data?: ConfigKey; headers?: ConfigKey } = {
    ...extraConfig,
  }
  if (data) {
    switch (method) {
      case 'GET':
        config.params = data
        break
      case 'POST':
      case 'PUT':
      case 'PATCH':
        config.data = data
        break
      default:
        break
    }
  }
  return fetcher(endpoint, { method, ...config })
}

export default fetcher
