//Background Color
// export const bgColorApp = '#242429' // old bgColorApp
export const bgColorApp = '#1e1e22'
export const bgModalBody = '#363639'

export const bgColorControl = '#18181b'

export const bgInput = '#131314'

//Text Color
export const textGray = '#75757f'
export const textGrayDrawer = '#b3b3b7'
export const textGrayWhite = '#c5c5cf'

//item hash
export const itemHash = '#1961e5' // xanh
export const itemHash1 = '#c5265a' // Cam
