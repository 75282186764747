import ReactGA from 'react-ga4'

export class GA {
  static init(measurementId: string) {
    ReactGA.initialize(measurementId)
  }
  static setUserId(userId: string) {
    console.log('UserId: ', userId)
    ReactGA.set({ user_id: userId })
  }

  static trackPageView(url: string) {
    console.log('Page: ', url)
    ReactGA.send({ hitType: 'pageview', page: url })
  }
  static trackEvent = (category: string, action: string, label?: string) => {
    console.log('Event: ', category, action, label)
    ReactGA.event({
      action,
      category,
      label,
    })
  }
}
