import { createSelector } from '@reduxjs/toolkit'
import { selectLastGame } from '../modules/game.slice'
import { selectOrderHistoryNoPaging } from '../modules/order.slice'

export const isLastGameWinner = createSelector([selectOrderHistoryNoPaging, selectLastGame], (orders, game) => {
  if (!game || !orders.length) return false
  const { lotteryResultHash, result } = game
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i]
    if (order.lotteryResultHash != lotteryResultHash) break
    if (order.bet == result) return true
  }
  return false
})

export const isLastGameLose = createSelector([selectOrderHistoryNoPaging, selectLastGame], (orders, game) => {
  if (!game || !orders.length) return false
  const { lotteryResultHash, result } = game
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i]
    if (order.lotteryResultHash !== lotteryResultHash) break
    if (order.bet !== result) return true
  }
  return false
})
