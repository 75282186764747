//format Tiền
export const formatVND = (amount: number | undefined | string): string | 0 => {
  if (!amount) return 0
  const re = '\\d(?=(\\d{3})+$)'
  const rounding = Math.round(Number(amount))
  return `${rounding.toString().replace(new RegExp(re, 'g'), '$&.')} đ`
}

//cut string khi quá dài

export const cutString = (value: string, length = 40) => {
  if (value.length < length) return `${value.substring(0, length)}`
  return `${value.substring(0, length)}...`
}

export function prettyPrint(num: number): number {
  if (num >= 1000000) {
    return parseFloat((num / 1000000).toFixed(1))
  } else if (num >= 1000) {
    return parseFloat((num / 1000).toFixed(1))
  } else {
    return num
  }
}

export function formatNumberInput(num: string | number) {
  if (!num) return num
  const text = (num + '').replace(/,/g, '')
  return Intl.NumberFormat('en-US').format(+text)
}
