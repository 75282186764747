import { Stack } from '@chakra-ui/react'
import React from 'react'
import AuthGame from '../../components/AuthGame'
import Betting from '../../components/hash-game/Betting'
import Countdown from '../../components/hash-game/Countdown'
import { bgColorControl } from '../../const/color'
import ControlSection from '../../components/hash-game/ControlSection'
import Wallet from '../../components/odd-even/Wallet'

export default function MainGameHOE() {
  return (
    <AuthGame>
      <Wallet />
      <ControlSection />
      <Stack backgroundColor={bgColorControl} borderBottomRadius={12}>
        <Countdown />
        <Betting />
      </Stack>
    </AuthGame>
  )
}
