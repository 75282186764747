import React from 'react'
import AuthGame from '../../components/AuthGame'
import { Stack, Text } from '@chakra-ui/react'
import Betting from '../../components/hash-game/Betting'
import Countdown from '../../components/hash-game/Countdown'
import { bgColorControl } from '../../const/color'
import ControlSection from '../../components/hash-game/ControlSection'
import Wallet from '../../components/hash-game/Wallet'

const MainGameHBS = () => {
  return (
    <AuthGame>
      <Wallet />
      <ControlSection />
      <Stack backgroundColor={bgColorControl} borderBottomRadius={12}>
        <Countdown />
        <Betting />
      </Stack>
    </AuthGame>
  )
}

export default MainGameHBS
