import { BetType } from '@store/entities/order.entity'

export declare type TrendItem = 'B' | 'S'

function parseTrends(trends: BetType[]): TrendItem[][] {
  const result: TrendItem[][] = []
  if (!trends.length) return result

  let columns = [trends[0]]
  for (let i = 1; i < trends.length; i++) {
    const x = trends[i]
    if (x == columns[0]) {
      columns.push(x)
    } else {
      result.push(columns)
      columns = [x]
    }
  }
  if (columns.length) {
    result.push(columns)
  }
  return result
}

function _trendsToBoard(_trends: TrendItem[][], colCount: number, rowCount: number) {
  const board: TrendItem[][] = []
  const trends = _trends.length <= colCount ? _trends : _trends.slice(_trends.length - colCount, _trends.length)

  for (let i = 0; i < colCount; i++) {
    board[i] = board[i] || []
    const columns = trends[i] || []
    let k = 0
    let lRow = 0
    for (let j = 0; j < columns.length; j++) {
      if (!board[i][j] && j < rowCount) {
        board[i][j] = columns[j]
      } else {
        if (!k) {
          k = i + 1
          lRow = j - 1
        } else {
          k = k + 1
        }
        board[k] = board[k] || []
        board[k][lRow] = columns[j]
      }
    }
  }
  return board.length <= colCount ? board : board.slice(board.length - colCount, board.length)
}

export function trendsToBoard(trends: BetType[], colCount = 14, rowCount = 7) {
  const trendsParsed = parseTrends(trends)
  return _trendsToBoard(trendsParsed, colCount, rowCount)
}
