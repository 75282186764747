import useGameId from '@/hooks/useGameId.ts'
import React from 'react'
import useCustomTranslation from '../../../hooks/useCustomTranslation.tsx'
import RuleGameContainer, {
  HexagonHeading,
  NormalParagraph,
  NormalTag,
  UnorderedTextList,
} from './RuleGameContainer.tsx'

export default function RuleHNIUNIU() {
  const { t } = useCustomTranslation()
  const gameId = useGameId()
  return (
    <RuleGameContainer
      title={
        <>
          {t(gameId)}
          <br />
          {t('ruleGame.bankerPlayer.title')}
        </>
      }
    >
      <HexagonHeading heading={t('ruleGame.niuniu.howToDetermine')} />
      <NormalParagraph>{t('ruleGame.niuniu.theLastFiveDigit')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.niuniu.bankerCattleNumber')} <NormalTag>0b9..</NormalTag> ={' '}
            <NormalTag>{t('ruleGame.niuniu.cattleNine')}</NormalTag>
          </>,
          <>
            {t('ruleGame.niuniu.playerCowNumber')} <NormalTag>..52C</NormalTag> ={' '}
            <NormalTag>{t('ruleGame.niuniu.cowSeven')}</NormalTag>
          </>,
        ]}
      />
      <NormalParagraph>{t('ruleGame.niuniu.numberIndicate')}</NormalParagraph>
      <HexagonHeading heading={t('ruleGame.niuniu.howToBet')} />
      <NormalParagraph>{t('ruleGame.niuniu.eachBetFrozen')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.niuniu.ifResult')} = <NormalTag>{t('win')}</NormalTag>
          </>,
        ]}
      />
      <NormalParagraph fontStyle={'italic'}>
        {t('ruleGame.niuniu.rewardAmount')} = <NormalTag>{t('ruleGame.niuniu.rewardAmountFormula')}</NormalTag>
      </NormalParagraph>
      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.niuniu.ifResult')} = <NormalTag>{t('lose')}</NormalTag>
          </>,
        ]}
      />
      <NormalParagraph fontStyle={'italic'}>
        {t('ruleGame.niuniu.returnedAmount')} = <NormalTag>{t('ruleGame.niuniu.returnedAmountFormula')}</NormalTag>
      </NormalParagraph>
      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.niuniu.multiple')}: <NormalTag>(1-10)</NormalTag> {t('ruleGame.niuniu.basedOnHigher')}
          </>,
        ]}
      />
      <NormalParagraph>{t('ruleGame.niuniu.toEnsure')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.niuniu.whenResultIs')} <NormalTag>{t('win')}</NormalTag>,{' '}
            {t('ruleGame.niuniu.theSystemWillRefundBets')}
          </>,
          <>
            {t('ruleGame.niuniu.whenResultIs')} <NormalTag>{t('lose')}</NormalTag>,{' '}
            {t('ruleGame.niuniu.theSystemWillRefundAmount')}
          </>,
        ]}
      />
      <NormalParagraph>{t('ruleGame.niuniu.theSystemWillNote')}</NormalParagraph>

      <NormalParagraph>
        {t('ruleGame.niuniu.theAboveWinning')} <NormalTag>SHA256</NormalTag>, {t('ruleGame.niuniu.willBeAnnounced')}
      </NormalParagraph>
      <NormalParagraph>{t('ruleGame.niuniu.example')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.niuniu.yourBetAmount')} <NormalTag>100</NormalTag>
          </>,
          <>
            {t('ruleGame.niuniu.frozenGuarantee')} <NormalTag>900</NormalTag>
          </>,
          <>
            {t('ruleGame.niuniu.gamePlayYouChoose')} <NormalTag>{t('player')}</NormalTag>
          </>,
          <>
            {t('ruleGame.niuniu.ifTheLotteryHash')} <NormalTag>***2235e</NormalTag>
          </>,
          <>
            <NormalTag>223</NormalTag> {t('ruleGame.niuniu.representsBankerPoints')} <NormalTag>2+2+3=7</NormalTag>
          </>,
          <>
            <NormalTag>35e</NormalTag> {t('ruleGame.niuniu.representsPlayerPoints')} <NormalTag>3+5+0=8</NormalTag>
          </>,
          <>
            {t('ruleGame.niuniu.winRate')} <NormalTag>0.98</NormalTag>
          </>,
          <>
            {t('ruleGame.niuniu.matchingGameplay')} <NormalTag>{t('win')}</NormalTag>
          </>,
          <>
            {t('ruleGame.niuniu.theSystemReward')} <NormalTag>100 * 9 + 100 + 100 * 8 * 0.98 = 1784</NormalTag>
          </>,
        ]}
      />
    </RuleGameContainer>
  )
}
