import { Box } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { gameActions, selectBoardConfig, selectTrendsBoard } from '@store/modules/game.slice'
import React, { useEffect, useRef, useState } from 'react'
import Chess from '../common/Chess.tsx'

const ChessBoardCanvas = () => {
  const containerRef = useRef(null)
  const { rows, columns } = useAppSelector(selectBoardConfig)
  const [colWidth, setColWidth] = useState(1)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const handleResize = () => {
      const expectedColOuterWidth = 22 + 2 // 68/3; // 66 content + 2px border
      const containerWidth = (containerRef.current as any)?.offsetWidth ?? 200
      const _columns = Math.floor(containerWidth / expectedColOuterWidth) // col must be dynamic based on UI width
      const colWidth = containerWidth / _columns

      // Chỉ cập nhật columns nếu nó thay đổi
      if (_columns !== columns) {
        dispatch(
          gameActions.setBoardConfig({
            rows,
            columns: _columns,
          }),
        )
      }
      setColWidth(colWidth)
    }

    handleResize()

    // window.addEventListener('resize', handleResize)
    // return () => {
    //   window.removeEventListener('resize', handleResize)
    // }
  }, [rows, columns, dispatch])

  const board = useAppSelector(selectTrendsBoard)
  return (
    <Box ref={containerRef} className="trend-box">
      <table>
        <tbody>
          {Array(rows)
            .fill(null)
            .map((i, ridx) => (
              <tr key={ridx}>
                {Array(columns)
                  .fill(null)
                  .map((i, cidx) => {
                    const rType = board[cidx][ridx]
                    return (
                      <td
                        key={cidx}
                        className="cellOuter"
                        style={{
                          width: colWidth,
                          height: colWidth,
                          position: 'relative',
                          textAlign: 'center',
                          lineHeight: '1',
                        }}
                      >
                        <Chess type={rType} />
                      </td>
                    )
                  })}
              </tr>
            ))}
        </tbody>
      </table>
    </Box>
  )
}

export default ChessBoardCanvas
