import ls from '../libs/local-storage.ts'

export function setSrcReferer() {
  if (isDifferValidOrigin(document.referrer)) {
    ls.set('siteReferer', document.referrer)
  }
}

export function getSrcReferer() {
  return ls.get('siteReferer')
}

export function isDifferValidOrigin(url: string) {
  try {
    const currentOrigin = window.location.origin
    const entryUrl = new URL(url)
    return entryUrl.origin !== currentOrigin
  } catch (e) {
    return false
  }
}
