import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getInitialLang(),
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=1.0.4`,
    },
    detection: {
      order: ['navigator', 'cookie', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie', 'localStorage'],
    },
  })

export function getInitialLang() {
  const params = new URLSearchParams(window.location.search)
  const langUrl = params.get('lang')
  let lang =
    langUrl || localStorage.getItem('i18nextLng')?.substring(0, 2) || navigator.language?.substring(0, 2) || 'en'

  const validLanguages = new Set(['en', 'zh', 'hi', 'pt', 'ko', 'ar', 'es'])
  if (!validLanguages.has(lang)) {
    lang = 'en'
    console.warn('{getInitialLang} fallback to en because of invalid lang: ', lang)
  }

  return lang
}

export default i18n
