import { ToastId, useToast, UseToastOptions } from '@chakra-ui/react'
import _throttle from 'lodash/throttle'
import React, { useCallback, useMemo, useRef } from 'react'

export interface CustomToastOptions extends UseToastOptions {
  title: React.ReactNode
  description?: React.ReactNode
  isClosable?: boolean
  duration?: number | null
  status?: 'success' | 'error' | 'warning' | 'info'
}

const useCustomToast = () => {
  const toast = useToast()
  const toastIdRef = useRef<ToastId | null>(null)

  const showToast = useCallback(
    ({
      title,
      description = '',
      status = 'success',
      duration = 1000,
      isClosable = false,
      ...props
    }: CustomToastOptions) => {
      toast.closeAll() // Đóng tất cả các toast đang mở

      const toastId = toast({
        title,
        description,
        status,
        duration,
        isClosable,
        position: 'bottom',

        ...props,
      })

      toastIdRef.current = toastId

      return toastId
    },
    [toast],
  )

  const showToastThrottle = useMemo(() => _throttle(showToast, 300), [showToast])

  const closeToast = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  return { showToastThrottle, closeToast }
}

export default useCustomToast
