import { Flex, IconButton, Image, Stack, Text, ToastId, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { IoCloseCircle } from 'react-icons/io5'
import useCustomTranslation from '../../hooks/useCustomTranslation'

export default function Notification() {
  const toast = useToast()
  const toastIdRef = React.useRef()

  useEffect(() => {
    const notificationSeen = false
    const onClose = () => {
      toast.close(toastIdRef.current as ToastId)
    }

    if (!notificationSeen) {
      toastIdRef.current = toast({
        position: 'top-center',
        render: () => <CGameAdsNotificationBox onClose={onClose} />,
        duration: 7000,
        containerStyle: {
          paddingTop: 2,
        },
      }) as any
    }

    return onClose
  }, [toast])

  return null
}

function CGameAdsNotificationBox(props: { onClose: any }) {
  const { t } = useCustomTranslation()
  const { onClose } = props
  return (
    <Flex
      backgroundColor="rgba(0, 0, 0, 0.9)"
      alignItems="center"
      justifyContent={'space-around'}
      className=" p-2 pl-[2px] h-[74px] rounded-[10px]"
    >
      <Image height="74px" src="/images/notify.webp" alt="" ml={{ base: 0, sm: '-5px' }} />
      <Stack className="relative">
        <Text color={'#bff701'} fontWeight={700} pt={0}>
          {t('globalBanner.title')}
        </Text>
        <Text mt={'-7px'} color={'#fff'} fontSize={13} lineHeight={'15px'} letterSpacing="1px">
          {t('globalBanner.description')}
        </Text>
        <div className="absolute right-0 top-0">
          <IconButton
            size="xs"
            _hover={{ background: 'none' }}
            background={'none'}
            onClick={onClose}
            icon={<IoCloseCircle fontSize={24} color="#888" />}
            aria-label={''}
            mt={-1}
          />
        </div>
      </Stack>
    </Flex>
  )
}
