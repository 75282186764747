export class Topics {
  static lastGame(gameId: string) {
    return `public/games/${gameId}/last_game`
  }

  static get topReward() {
    return `public/games/top_reward`
  }

  static configGame(gameId: string) {
    return `public/games/${gameId}/config`
  }
  static walletBalance(playerId: string) {
    return `wallet/players/${playerId}/balance`
  }
}
