import gameFetcher from '@libs/gameFetcher'
import { OrderDetail, OrderRequest } from '../entities/order.entity'
import { BaseGetManyResponse } from '../entities/response.entity'

export class OrderService {
  static async createOrder(gameID: string, order: OrderRequest): Promise<OrderDetail> {
    const resp = await gameFetcher.post(`/${gameID}/order/bet`, order)
    return resp.data as OrderDetail
  }
  static async getOrderHistory(
    gameID: string = 'H_BS',
    page: number = 1,
    limit: number = 5,
  ): Promise<BaseGetManyResponse<OrderDetail>> {
    const resp = await gameFetcher.get(`/${gameID}/order/history?page=${page}&limit=${limit}`)
    return resp.data as BaseGetManyResponse<OrderDetail>
  }

  static async getOrderHistoryHomePage(gameID: string): Promise<BaseGetManyResponse<OrderDetail>> {
    const resp = await gameFetcher.get(`/${gameID}/order/history?page=1&limit=10`)
    return resp.data as BaseGetManyResponse<OrderDetail>
  }
}

// function isNetworkError(error: unknown): error is { response: undefined } {
//   return typeof error === 'object' && error !== null && !('response' in error)
// }
