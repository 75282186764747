import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { Wallet } from '../entities/wallet.entity'
import { login } from './auth.slice'
import { createOrder } from './order.slice'
import { getWalletByCurrency } from './wallet.slice'

export interface PreferenceState {
  lastPlayerId?: string
  activeWalletId: string
  activeCurrency: string
  betAmount?: Record<string, number>
}

const initialState: PreferenceState = {
  activeWalletId: '',
  activeCurrency: '',
  betAmount: {},
}

export const preferenceSlice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    activeWalletUpdated(state, action: PayloadAction<Wallet>) {
      state.activeWalletId = action.payload?.id
      state.activeCurrency = action.payload?.currency
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const playerId = action.payload?.player?.id
        const walletId = action.payload?.player?.default_wallet?.id || ''
        if (playerId != state.lastPlayerId) {
          state.activeWalletId = walletId
        }
        state.lastPlayerId = playerId
      })
      .addCase(getWalletByCurrency.fulfilled, (state, action) => {
        const wallet = action.payload
        const id = wallet?.id
        if (!wallet?.is_enable) return

        state.activeWalletId = id
        state.activeCurrency = wallet?.currency
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        const gameId = action.payload.game_type
        const amount = action.payload.amount
        if (!state.betAmount) {
          state.betAmount = {}
        }
        if (gameId && amount) {
          state.betAmount[gameId] = Number(amount)
        }
      })
  },
})

export const selectLastPlayerId = (state: RootState) => state.preference.lastPlayerId
export const selectActiveWalletId = (state: RootState) => state.preference.activeWalletId
export const selectActiveCurrency = (state: RootState) => state.preference.activeCurrency
const emptyObj: Record<string, number> = {}
export const selectBetAmount = (state: RootState) => state.preference.betAmount || emptyObj
export const selectBetAmountByGame = (gameId: string) => (state: RootState) => selectBetAmount(state)[gameId]

export default preferenceSlice
