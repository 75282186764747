import { Box, Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

export function LotteryHashHNIUNIU({
  hash,
  showEllipsis = false,
  result,
  children,
}: {
  hash: string
  showEllipsis?: boolean
  result?: ReactNode
  children?: string
}) {
  if (!hash) {
    return null
  }

  // Lấy 5 ký tự cuối từ hash
  const lastFiveChars = hash.slice(-5)

  // Chuyển ký tự thành số (10 nếu không phải số)
  const convertCharToNumber = (char: string): number => {
    return isNaN(Number(char)) ? 10 : Number(char)
  }

  // Tính toán giá trị cho phần "first" và "last"
  const first = lastFiveChars.slice(0, 3)
  const firstValue = processSum(
    convertCharToNumber(first[0]),
    convertCharToNumber(first[1]),
    convertCharToNumber(first[2]),
  )

  const last = lastFiveChars.slice(2, 5)
  const lastValue = processSum(convertCharToNumber(last[0]), convertCharToNumber(last[1]), convertCharToNumber(last[2]))

  // Xác định ký tự giữa
  let middleChar = ''
  if (firstValue > lastValue) {
    middleChar = 'B'
  } else if (firstValue < lastValue) {
    middleChar = 'P'
  } else {
    middleChar = 'TIE'
  }

  // Cắt bớt phần hash trước 5 ký tự cuối
  const cutHash = hash.slice(0, -5)

  return (
    <div style={{ width: showEllipsis ? '100%' : 'auto' }}>
      <Text color="white" textAlign="left">
        {showEllipsis ? <Text as="span">{cutHash}</Text> : '...'}
        {lastFiveChars.split('').map((char, index) => (
          <Text key={index} as="span" color={isNaN(Number(char)) ? 'white' : '#d5fe47'}>
            {char}
          </Text>
        ))}
        {`(B${firstValue !== 0 ? firstValue : ''} - P${lastValue !== 0 ? lastValue : ''})`}
        {result}
      </Text>
    </div>
  )
}

// Hàm tính tổng của 3 số và lấy phần dư (nếu lớn hơn 10)
function processSum(a: number, b: number, c: number): number {
  const sum = a + b + c
  return sum >= 10 ? sum % 10 : sum
}
