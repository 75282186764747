import {
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react'
import React from 'react'

type AppDrawerProps = DrawerProps & {
  title?: string
  overlay?: boolean
}
export function AppDrawer(props: AppDrawerProps) {
  const { title, overlay, children, ...drawerProps } = props

  const closeBtnInContent = !title

  return (
    <Drawer placement="bottom" isFullHeight={false} preserveScrollBarGap={true} {...drawerProps}>
      {overlay && <DrawerOverlay background="rgba(0, 0, 0, 0.7)" />}
      <DrawerContent backgroundColor={'transparent'}>
        <Container p={0} borderTopRadius={'12px'} overflow={'hidden'}>
          {title && (
            <DrawerHeader
              backgroundColor="#363639"
              padding={'0 19px'}
              h={'40px'}
              fontSize={'18px'}
              lineHeight={'40px'}
              borderBottom={'1px solid #2f2f31'}
              textAlign={'center'}
              alignItems="center"
              position={'relative'}
              borderTopRadius={'12px'}
            >
              {title}
              {!closeBtnInContent && (
                <DrawerCloseButton
                  outlineOffset="none"
                  outline="none !important"
                  boxShadow="none !important"
                  border="none !important"
                  color={'#fff'}
                  right={'6px'}
                  top={1}
                />
              )}
            </DrawerHeader>
          )}
          <DrawerBody maxHeight="80vh" padding={0}>
            {closeBtnInContent && (
              <Box position={'relative'}>
                <DrawerCloseButton
                  color={'#fff'}
                  right="6px"
                  top={1}
                  borderRadius={'50%'}
                  backgroundColor={'rgba(0, 0, 0, 0.5)'}
                  outline="none !important"
                  boxShadow="none !important"
                  border="none"
                  w={6}
                  h={6}
                />
              </Box>
            )}

            {/* must be the same as in LayoutFullWidth */}
            {children}
          </DrawerBody>
        </Container>
      </DrawerContent>
    </Drawer>
  )
}
