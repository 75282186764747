import { RootState } from '@/redux/store'
import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit'

interface ActionDetail {
  status: 'pending' | 'fulfilled' | 'rejected'
  pending?: PayloadAction<any>
  fulfilled?: PayloadAction<any>
  rejected?: PayloadAction<any>
}
export interface CommonState {
  actions: Record<string, ActionDetail>
}

const initialState: CommonState = {
  actions: {},
}

function getActionKey(action: PayloadAction<any>) {
  const type = action?.type || ''
  return type.replace('/fulfilled', '').replace('/rejected', '').replace('/pending', '')
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending, (state, action) => {
        const key = getActionKey(action)
        state.actions[key] = {
          status: 'pending',
          pending: action,
        }
      })
      .addMatcher(isFulfilled, (state, action) => {
        const key = getActionKey(action)
        const old = state.actions[key] || {}
        state.actions[key] = {
          ...old,
          status: 'fulfilled',
          fulfilled: action,
        }
      })
      .addMatcher(isRejected, (state, action) => {
        const key = getActionKey(action)
        const old = state.actions[key] || {}
        state.actions[key] = {
          ...old,
          status: 'rejected',
          rejected: action,
        }
      })
  },
})

export default commonSlice

export function selectIsLoadingAction(action: any) {
  const key = getActionKey(action)
  return (state: RootState) => state.common.actions[key]?.status == 'pending'
}
