import React from 'react'
import AuthGame from '../../components/AuthGame'
import { Stack, Text } from '@chakra-ui/react'
import ControlSection from '../../components/hash-game/ControlSection'
import { bgColorControl } from '../../const/color'
import CountdownHBP from '../../components/banker-player/Countdown'
import BettingHBP from '../../components/banker-player/Betting'
import WalletHBP from '../../components/banker-player/Wallet'

export default function MainGameBankerPlayer() {
  return (
    <AuthGame>
      <WalletHBP />
      <ControlSection />
      <Stack backgroundColor={bgColorControl} borderBottomRadius={12}>
        <CountdownHBP />
        <BettingHBP />
      </Stack>
    </AuthGame>
  )
}
