import { GA } from '@/utils/analytics'
import { Middleware } from '@reduxjs/toolkit'
import { ServiceConfig } from '@store/services/service-config'
import { REHYDRATE } from 'redux-persist'
import { RootState } from '..'
import authSlice, { login, selectToken, selectUserId } from '../modules/auth.slice'
import authService from '../services/auth.service'

export const authMiddleware: Middleware = (storeAPI) => (next) => (action: any) => {
  const result = next(action)
  if (action.type === login.fulfilled) {
    const state = storeAPI.getState() as RootState
    const userId = selectUserId(state)
    if (userId) {
      GA.setUserId(userId)
    }
  }
  if (action.type === REHYDRATE) {
    const state = storeAPI.getState() as RootState
    const userId = selectUserId(state)
    if (userId) {
      GA.setUserId(userId)
    }

    const token = selectToken(state)
    if (token && authService.isTokenExpired(token)) {
      storeAPI.dispatch(authSlice.actions.logout())
      return result
    }
    if (token) {
      ServiceConfig.token = token
    }
  }
  return result
}
