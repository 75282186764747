import { Stack } from '@chakra-ui/react'
import React from 'react'
import AuthGame from '../../components/AuthGame'
import ControlSection from '../../components/hash-game/ControlSection'
import CountdownH_NIUNIU from '../../components/niuniu/Countdown'
import WalletHNIUNIU from '../../components/niuniu/Wallet'
import { bgColorControl } from '../../const/color'
import BettingHNIUNIU from '../../components/niuniu/Betting'

export default function MainGameNiuNiu() {
  return (
    <AuthGame>
      <WalletHNIUNIU />
      <ControlSection />
      <Stack backgroundColor={bgColorControl} borderBottomRadius={12}>
        <CountdownH_NIUNIU />
        <BettingHNIUNIU />
      </Stack>
    </AuthGame>
  )
}
