import useGameId from '@/hooks/useGameId.ts'
import { Text } from '@chakra-ui/react'
import React from 'react'
import useCustomTranslation from '../../../hooks/useCustomTranslation.tsx'
import RuleGameContainer, {
  HexagonHeading,
  NormalParagraph,
  NormalTag,
  UnorderedTextList,
} from './RuleGameContainer.tsx'

export default function RuleHLUCKY() {
  const { t } = useCustomTranslation()
  const gameId = useGameId()
  return (
    <RuleGameContainer
      title={
        <>
          {t(gameId)}
          <br />
          {t('ruleGame.bankerPlayer.title')}
        </>
      }
    >
      <HexagonHeading heading={t('ruleGame.lucky.howToDetermine')} />
      <NormalParagraph>{t('ruleGame.lucky.paragraph1')}</NormalParagraph>

      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.lucky.listItem1')}
            <NormalTag>
              ******5
              <Text as="span" color={'#c3fb04'}>
                e5
              </Text>
            </NormalTag>{' '}
            = <NormalTag>{t('ruleGame.lucky.cowSeven')}</NormalTag>
          </>,
          <>
            {t('ruleGame.lucky.listItem2')}
            <NormalTag>
              ******59{' '}
              <Text as="span" color={'#c3fb04'}>
                ee
              </Text>
            </NormalTag>{' '}
            = <NormalTag>{t('ruleGame.lucky.cattleNine')}</NormalTag>
          </>,
        ]}
      />
      <NormalParagraph>{t('ruleGame.lucky.rebateAmount')}</NormalParagraph>
      <NormalParagraph>{t('ruleGame.lucky.paragraph3')}</NormalParagraph>

      <HexagonHeading heading={t('ruleGame.lucky.example')} />
      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.lucky.exampleItems.betAmount')}
            <NormalTag>
              <Text as="span" color={'#c3fb04'}>
                100
              </Text>
            </NormalTag>
            <Text>
              {t('ruleGame.lucky.exampleItems.gameplayChoice')} <NormalTag>{t('ruleGame.lucky.result1')}</NormalTag>
            </Text>
          </>,
          <>
            {t('ruleGame.lucky.exampleItems.lotteryHash')}
            <NormalTag>
              ******59
              <Text as="span" color={'#c3fb04'}>
                e9
              </Text>
            </NormalTag>
            <Text>
              {t('ruleGame.lucky.exampleItems.lotteryResult')} <NormalTag>e9</NormalTag>
            </Text>
          </>,
          <>
            {t('ruleGame.lucky.exampleItems.result')}
            <NormalTag>
              <Text as="span" color={'#c3fb04'}>
                {t('ruleGame.lucky.result2')}
              </Text>
            </NormalTag>
            <Text>
              {t('ruleGame.lucky.exampleItems.matchResult')}: <NormalTag color={'#c3fb04'}>{t('WIN')}</NormalTag>
            </Text>
          </>,
          <>
            {t('ruleGame.lucky.exampleItems.rewardAmount')}
            <NormalTag>
              100 * 1.95 =
              <Text as="span" color={'#c3fb04'}>
                195
              </Text>
            </NormalTag>
          </>,
        ]}
      />
    </RuleGameContainer>
  )
}
