import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

const isDev = import.meta.env.DEV

if ('serviceWorker' in navigator && !isDev) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope)

      // Kiểm tra nếu có service worker mới
      registration.onupdatefound = () => {
        const newWorker = registration.installing
        newWorker?.addEventListener('statechange', () => {
          console.log('Service Worker statechange')
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            console.log('Service worker push force update')
            newWorker.postMessage({ type: 'SKIP_WAITING' })
          }
        })
      }
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error)
    })

  // Lắng nghe thông điệp từ service worker để làm mới trang
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('Controller changed')
    window.location.reload()
  })
}
