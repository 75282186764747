export class ServiceConfig {
  private static _token: string = ''

  public static get token(): string {
    return this._token
  }
  public static set token(val: string) {
    this._token = val
  }
}
