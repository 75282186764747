import { Configs } from '@/const/configs'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { isEmpty, keyBy } from 'lodash'
import { RootState } from '..'
import endpoint from '../../../const/endpoint'
import { fetchFn } from '../../../libs/gameFetcher'
import { WalletPlayUser } from '../entities/game.entity'
import { Wallet } from '../entities/wallet.entity'
import { AppError, errors } from '../models/error.model'
import { login, selectUser } from './auth.slice'
import { createThunk } from './common'
import { selectActiveCurrency, selectActiveWalletId } from './preference.slice'

export interface WalletState {
  walletIds: string[]
  supportedCurrency: string[]
  details: Record<string, Wallet>
}

const initialState: WalletState = {
  walletIds: [],
  supportedCurrency: [],
  details: {},
}

export const getListWallets = createThunk('wallet/getListWallets', async (_: void) => {
  const response = await fetchFn<Wallet[]>('GET', `${Configs.walletApiUrl}${endpoint.LIST_WALLETS}`)
  return response.data.data
})
export const getWalletById = createThunk('wallet/getById', async (id: string) => {
  const response = await fetchFn<Wallet>('GET', `${Configs.walletApiUrl}${endpoint.LIST_WALLETS}/${id}`)
  return response.data.data
})
export const getWalletByCurrency = createThunk('wallet/getWalletByCurrency', async (currency: string) => {
  const wallet = await _getWalletByCurrency(currency).catch((err) => {
    if (err instanceof AxiosError) {
      const code = err?.response?.data?.code
      const message = err?.response?.data?.message
      if (code) {
        throw new AppError(code, message, { currency })
      }
    }
    throw err
  })

  if (wallet.is_enable) return wallet
  throw new AppError(errors.ErrCurrencyNotSupported, 'Currency not supported', { currency })
})

async function getSupportedCurrency() {
  const response = await fetchFn<string[]>('GET', `${Configs.walletApiUrl}${endpoint.LIST_WALLETS_ENABLED}`)
  return response.data.data
}

async function _getWalletByCurrency(currency: string) {
  return fetchFn<Wallet>('GET', `${Configs.walletApiUrl}${endpoint.WALLET}/${currency}`)
    .then((res) => res.data.data)
    .catch(async (err) => {
      const currencies = await getSupportedCurrency()
      if (currencies.includes(currency)) {
        const wallet: Wallet = {
          id: '',
          currency,
          amount: 0,
          is_enable: true,
        }
        return wallet
      }
      throw err
    })
}

export const getListWalletEnabled = createThunk('wallet/getListEnabled', async (_: void) => {
  return getSupportedCurrency()
})

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    walletUpdated(state, action: PayloadAction<WalletPlayUser>) {
      const wallet = action.payload
      const oldWallet = state.details[wallet.id] || {}
      state.details[wallet.id] = {
        ...oldWallet,
        currency: wallet.currency,
        amount: Number(wallet.balance),
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const wallet = action.payload?.player?.default_wallet
        const id = wallet?.id
        if (!id) return

        state.details[id] = {
          ...(state.details[id] || {}),
          ...wallet,
          amount: Number(wallet.amount),
        }
      })
      .addCase(getListWallets.fulfilled, (state, action) => {
        const wallets = action.payload
        state.walletIds = wallets.map(({ id }) => id)
        state.details = {
          ...state.details,
          ...keyBy(wallets, 'id'),
        }
      })
      .addCase(getWalletById.fulfilled, (state, action) => {
        const wallet = action.payload
        if (isEmpty(wallet)) return

        state.details = {
          ...state.details,
          [wallet.id]: wallet,
        }
      })
      .addCase(getWalletByCurrency.fulfilled, (state, action) => {
        const wallet = action.payload
        if (isEmpty(wallet)) return

        state.details = {
          ...state.details,
          [wallet.id]: wallet,
        }
      })
      .addCase(getListWalletEnabled.fulfilled, (state, action) => {
        state.supportedCurrency = action.payload
      })
  },
})

export const selectSupportedCurrency = (state: RootState) => state.wallet.supportedCurrency
export const selectWalletIds = (state: RootState) => state.wallet.walletIds
export const selectWalletDetails = (state: RootState) => state.wallet.details
export const selectListWallets = createSelector([selectWalletIds, selectWalletDetails], (ids, details) =>
  ids.map((id) => details[id]),
)
const emptyWallet: Wallet = {
  id: '',
  currency: 'USDT',
  amount: 0,
}
export const selectWalletDetail = (id: string) => (state: RootState) => state.wallet.details[id]
export const selectDefaultWallet = createSelector([selectUser, selectWalletDetails], (user, details) => {
  const defaultWalletId = user?.default_wallet?.id
  return details[defaultWalletId || '']
})
export const selectActiveWallet = (state: RootState) => {
  return state.wallet.details[selectActiveWalletId(state)]
}
export const selectWallet = createSelector(
  [selectActiveWallet, selectDefaultWallet, selectActiveCurrency, selectWalletDetails, selectWalletIds],
  (activeWallet, defaultWallet, currency, details, ids) => {
    if (activeWallet && activeWallet.is_enable) return activeWallet
    if (currency && currency != activeWallet?.currency)
      return {
        ...emptyWallet,
        currency,
        is_enable: true,
      }
    if (defaultWallet && defaultWallet.is_enable) return defaultWallet
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index]
      const wallet = details[id]
      if (wallet && wallet.is_enable) return wallet
    }
    return {
      ...emptyWallet,
      currency: 'USDT',
      is_enable: true,
    }
  },
)
export const selectCurrency = (state: RootState) => selectWallet(state).currency
export const selectBalanceAmount = (state: RootState) => selectWallet(state).amount

export default walletSlice
