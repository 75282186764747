import { Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useCustomTranslation from '../../hooks/useCustomTranslation'

/**
 * Show lottery hash with highlight in the form of:
 * 6033624dcc52fa5127733a021718f7b6404328413c2de9429b7f8e22765ce85f
 * 6033624dcc52...765ce85f
 * ...765ce85f
 */
export function LotteryHash(props: {
  text: string
  type?: 'full' | 'middleEllipsis' | 'suffixOnly'
  prefixLength?: number // in case of type!=full
  suffixLength?: number // in case of type!=full
  children?: any
}) {
  const { t } = useCustomTranslation()
  const { type, text: hash, prefixLength, suffixLength, children, ...rest } = props
  if (!hash) return null

  let h = hash
  if (type === 'middleEllipsis') {
    h = hash.substring(0, prefixLength) + '...' + hash.substring(hash.length - (suffixLength ?? 0))
  } else if (type === 'suffixOnly') {
    h = '...' + hash.substring(hash.length - (suffixLength ?? 0))
  }
  const { prefix, digit, suffix } = parsePrizeHash(h)

  return (
    <Text {...rest}>
      {prefix}
      <span style={{ color: '#d5fe47', textTransform: 'uppercase' }}>{digit}</span>
      {suffix}
      {children}
    </Text>
  )
}

export function parsePrizeHash(text: string) {
  if (!text) return null
  const [_all, prefix, digit, suffix] = text.match(/(.*)(\d)(\D*)/) || []
  if (!_all) return null

  return {
    prefix,
    digit,
    suffix,
  }
}
