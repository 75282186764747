import { combineSlices, configureStore } from '@reduxjs/toolkit'
import middlewares from '@store/middlewares'
import animationSlice from '@store/modules/animation.slice'
import positionSlice from '@store/modules/animationHBP.slice'
import soundSlice from '@store/modules/audio.slice'
import authSlice from '@store/modules/auth.slice'
import gameSlice from '@store/modules/game.slice'
import orderSlice from '@store/modules/order.slice'
import walletSlice from '@store/modules/wallet.slice'
import { useDispatch, useSelector } from 'react-redux'
import { PersistConfig, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import commonSlice from './modules/common.slice'
import preferenceSlice from './modules/preference.slice'

const rootReducer = combineSlices(
  authSlice,
  orderSlice,
  gameSlice,
  soundSlice,
  animationSlice,
  positionSlice,
  walletSlice,
  preferenceSlice,
  commonSlice,
)

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'preference', 'wallet'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
