import { ErrorOptions, popupError } from '@/components/common/app'
import { isRejected } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { keyBy } from 'lodash'
import { Middleware } from 'redux'
import { AppError } from '../models/error.model'
import { getWalletByCurrency } from '../modules/wallet.slice'

const muteActions = keyBy([getWalletByCurrency.rejected.type])

export const handleErrorMiddleware: Middleware = (storeAPI) => (next) => (action: any) => {
  const result = next(action)

  if (!isRejected(action)) {
    return result
  }

  const err = getErrorFromAction(action)
  if (err) {
    popupError(err)
  }

  return result
}

function getErrorFromAction(action: any): ErrorOptions | null {
  const { payload, type } = action || {}
  const mute = !!muteActions[type]

  if (payload instanceof AxiosError) {
    const code = payload.response?.data?.code || payload.code
    const message = payload.response?.data?.message || payload.message
    return {
      message,
      code,
      mute,
    }
  }

  if (payload instanceof AppError) {
    const code = payload.code
    const message = payload.message
    return {
      message,
      code,
      mute,
      meta: payload.data,
    }
  }

  return null
}
