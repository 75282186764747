import useGameId from '@/hooks/useGameId'
import { Flex, Image, Stack, Text } from '@chakra-ui/react'
import { RootState, useAppDispatch, useAppSelector } from '@store'
import { setPosition } from '@store/modules/animationHBP.slice'
import { selectDiffTime, selectGameConfigs, selectGameEndTime, selectGameStartTime } from '@store/modules/game.slice'
import orderSlice, { selectBetAmountByType, selectOrderBetType } from '@store/modules/order.slice'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { GrMoney } from 'react-icons/gr'
import { gameConfigs } from '../../const/game-type'
import useCustomTranslation from '../../hooks/useCustomTranslation'
import Speedometer from '../UI/Clock'

function Clock({ style }: { style?: React.CSSProperties }) {
  const isMuted = useAppSelector((state: RootState) => state.sound.isMuted)
  const startTime = useAppSelector(selectGameStartTime) || 0
  const endTime = useAppSelector(selectGameEndTime) || 0
  const diffTime = useAppSelector(selectDiffTime)
  const total = Math.round((endTime - startTime) / 1000) || 30
  const value = (Date.now() + diffTime - startTime) / 1000
  const current = value >= 0 && value <= total ? value : 0
  const [currentSecond, setCurrentSecond] = useState(current)
  const audioRef: any = useRef<HTMLAudioElement | null>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      const value = (Date.now() + diffTime - startTime) / 1000
      const current = value >= 0 && value <= total ? value : 0
      setCurrentSecond(Math.round(current))
    }, 200)

    return () => clearInterval(interval)
  }, [startTime, diffTime, total])

  useEffect(() => {
    if (currentSecond === 0) {
      if (audioRef.current) {
        if (isMuted) audioRef.current.play()
        else audioRef.current.pause()
      }
    }
  }, [currentSecond])

  return (
    <Flex style={style} alignItems="center">
      <audio autoPlay ref={audioRef} src="/sound/open.mp3" />
      <Speedometer totalSeconds={total} current={currentSecond} />
    </Flex>
  )
}

function BigAndSmall({ style }: { style?: React.CSSProperties }) {
  const { t } = useCustomTranslation()
  const gameId = useGameId()
  const dispatch = useAppDispatch()
  const gameConfig = useAppSelector(selectGameConfigs)
  const refs = useRef<Array<React.RefObject<HTMLDivElement>>>([React.createRef(), React.createRef(), React.createRef()])
  const banker = useAppSelector(selectBetAmountByType(gameId)('BANKER'))
  const tie = useAppSelector(selectBetAmountByType(gameId)('TIE'))
  const player = useAppSelector(selectBetAmountByType(gameId)('PLAYER'))
  const amountMap = [banker, tie, player]

  const bet = useAppSelector(selectOrderBetType)
  const config = gameConfigs[gameId]
  const betTypes = config?.choices || config?.type || []
  useEffect(() => {
    if (!isEmpty(betTypes) && !betTypes.includes(bet)) {
      dispatch(orderSlice.actions.betTypeUpdated(betTypes[0]))
    }
  }, [gameId])

  useEffect(() => {
    refs.current.forEach((ref, index) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect()
        dispatch(setPosition({ index, x: rect.left, y: rect.top }))
      }
    })
  }, [dispatch])

  return (
    <Flex style={style} w="100%" alignItems="center" justifyContent="space-around" gap={3}>
      <Flex gap={2} w="100%" h="100%" alignItems={'flex-end'}>
        {betTypes.map((item, i) => {
          const isActive = item == bet
          return (
            <Stack
              key={item}
              cursor="pointer"
              alignItems="center"
              position="relative"
              // mt={i === 1 ? 5 : 0}
              onClick={() => {
                dispatch(orderSlice.actions.betTypeUpdated(item))
              }}
              id={`bet-type-btn-${item}`}
            >
              <Image
                src={isActive ? `/images/gaming/bettingHBP${item}Focus.png` : `/images/gaming/bettingHBP${item}.png`}
                mb={i === 1 ? '-4px' : 0}
              />
              <Stack
                width={'100%'}
                alignItems="center"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                textAlign={'center'}
                color={isActive ? '#B4FF02' : 'white'}
              >
                <Stack alignItems="center" gap={1}>
                  <Text
                    className={`type${i}`}
                    position="relative"
                    textTransform="uppercase"
                    fontSize={{
                      base: '16px',
                    }}
                    fontWeight={500}
                  >
                    {t(item)}
                  </Text>
                  <Flex
                    mt={-2}
                    className="typeBig"
                    position="relative"
                    textTransform="uppercase"
                    fontSize={{
                      base: '12px',
                    }}
                    fontWeight={600}
                    cursor="pointer"
                    pb={1}
                  >
                    {i === 1 ? (
                      <Flex gap={1} color={isActive ? '#B4FF02' : '#dc8098'}>
                        <Text>1</Text>
                        <Text>:</Text>
                        <Text>{gameConfig.tieRate}</Text>
                      </Flex>
                    ) : (
                      <Flex gap={1} color={isActive ? '#B4FF02' : '#dc8098'}>
                        <Text>1</Text>
                        <Text>:</Text>
                        <Text>{gameConfig.winRate}</Text>
                      </Flex>
                    )}
                  </Flex>
                </Stack>
                <Flex ref={refs.current[i]} gap={2} alignItems="center" mt={-3} color={isActive ? 'yellow' : 'gray'}>
                  <GrMoney fontSize={10} />
                  <Text
                    mt={1}
                    color={amountMap[i] > 0 ? 'white' : '#dc8098'}
                    fontSize={{
                      base: '12px',
                    }}
                  >
                    {amountMap[i].toFixed(2) || '0.00'}
                  </Text>
                </Flex>
              </Stack>
            </Stack>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default function CountdownHBP() {
  return (
    <Stack
      background="rgba(76, 175, 80, 0.0)"
      mt={{
        base: -8,
      }}
      gap={1}
      position="relative"
    >
      <Stack>
        <Image src="/images/Group1.webp" alt="" />
      </Stack>
      <Stack position="absolute" width="100%" top={'47%'} left={'50%'} transform="translate(-50%, -50%)" w="100%" p={2}>
        <Flex justifyContent="center" position="relative">
          <Stack
            top={{
              base: 2,
              'galaxy-s8': 1,
              'iphone-14-pro-max': 3,
              'galaxy-s20': 2,
              'galaxy-zfold5': 1,
              'pc-500-up': '18px',
            }}
            position="absolute"
          >
            <Clock />
          </Stack>
        </Flex>
        <Flex w="100%" justifyContent="center" p={2}>
          <BigAndSmall />
        </Flex>
      </Stack>
    </Stack>
  )
}
