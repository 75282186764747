import { Button, Flex, Text } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'
import useCustomTranslation from '../../hooks/useCustomTranslation'

export default function CopyAll({ cpyTxt }: { cpyTxt: string }) {
  const { t } = useCustomTranslation()
  const [checked, setChecked] = useState(false)

  const copy = useCallback(() => {
    navigator.clipboard.writeText(cpyTxt).catch(console.warn)
    setChecked(true)
    setTimeout(() => {
      setChecked(false)
    }, 1000)
  }, [cpyTxt])
  return (
    <Button
      aria-label="Copy Content"
      aria-labelledby="Copy Content"
      w={'100%'}
      backgroundColor="#c7fb14"
      size="xs"
      mt={2}
      onClick={copy}
      _hover={{ backgroundColor: '#A3CE0C' }}
    >
      {checked ? t('common.copied') : t('common.copyAll')}
      {checked && (
        <Flex className="fixed text-[12px] text-white top-[25%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#333] p-1 items-center gap-1 rounded-md z-1100000000">
          <FaCheck />
          <Text>Copy Success</Text>
        </Flex>
      )}
    </Button>
  )
}
