import { Configs } from '@/const/configs'
import { useAppSelector } from '@/redux/store'
import { selectToken, selectUserId } from '@/redux/store/modules/auth.slice'
import mqtt, { IClientOptions, MqttClient } from 'mqtt'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import MqttContext from './context'
import { ConnectorProps, IMqttContext } from './types'

export default function Connector({ children }: ConnectorProps) {
  // Using a ref rather than relying on state because it is synchronous
  const clientValid = useRef(false)
  const [connectionStatus, setStatus] = useState<string | Error>('Offline')
  const [client, setClient] = useState<MqttClient | null>(null)
  const userId = useAppSelector(selectUserId)
  const token = useAppSelector(selectToken)
  const options: IClientOptions = {
    clientId: 'cg_bo_trading' + uuidv4(),
    username: userId,
    password: token,
    clean: true,
    reconnectPeriod: 1000,
    connectTimeout: 10000,
    path: '/mqtt',
  }
  const parserMethod = (msg: any) => msg

  useEffect(() => {
    if (!userId || !token) return

    if (!client && !clientValid.current) {
      // This synchronously ensures we won't enter this block again
      // before the client is asynchronously set
      clientValid.current = true

      const mqttClient = mqtt.connect(Configs.socketUrl, options)
      mqttClient.on('connect', () => {
        setStatus('Connected')
        // For some reason setting the client as soon as we get it from connect breaks things
        setClient(mqttClient)
      })
      mqttClient.on('reconnect', () => {
        setStatus('Reconnecting')
      })
      mqttClient.on('error', (err) => {
        // console.log(`Connection error: ${err}`);
        setStatus(err.message)
      })
      mqttClient.on('offline', () => {
        setStatus('Offline')
      })
      mqttClient.on('end', () => {
        setStatus('Offline')
      })
    }
  }, [client, clientValid, token])

  // Only do this when the component unmounts
  useEffect(
    () => () => {
      if (client) {
        client.end(true)
        setClient(null)
        clientValid.current = false
      }
    },
    [client, clientValid],
  )

  console.log('connection:', connectionStatus)
  // This is to satisfy
  // https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/jsx-no-constructed-context-values.md
  const value: IMqttContext = useMemo<IMqttContext>(
    () => ({
      connectionStatus,
      client,
      parserMethod,
    }),
    [connectionStatus, client, parserMethod],
  )

  return <MqttContext.Provider value={value}>{children}</MqttContext.Provider>
}
