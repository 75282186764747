const errorCode = {
  office: {
    INCORRECT_ID: 101,
    NOT_FOUND: 102,
  },
  card: {
    INVALID_CREDIT: 201,
    EXCEED_CREDIT_LIMIT: 202,

    INVALID_CARD_ID: 401,
    CARD_NOT_ATTACHED_TO_DEPARTMENT: 402,
    CARD_NOT_FOUND: 403,
    PHONE_ALREADY_ATTACHED: 405,
    INVALID_TIME: 406,
    TRANSACTION_NOT_FOUND: 407,
    EMPLOYEE_ID_EXISTS: 415,

    CUSTOMER_NOT_FOUND: 601,
  },
  department: {
    NOT_FOUND: 301,
    INVALID_DEPARTMENT_ID: 303,
    EXCEED_CREDIT_LIMIT_DEP: 203,
  },
  account: {
    PASSWORD_NOT_MATCH: 702,
    INVALID_NEW_PASSWORD: 703,
    CANNOT_CHANGE_PASSWORD: 704,
  },
  other: {
    CUSTOMER_NOT_FOUND: 502,
    MAINTENANCE: 503,
  },
}

export default errorCode
