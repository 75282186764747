export type ListenerFn = (...args: any[]) => void

interface IframeEvent {
  event: IframeCommunicationEvent
  params: any
}

export enum IframeCommunicationEvent {
  GO_BACK = 'go_back',
  STATUS_BAR_COLOR = 'status_bar_color',
}

export class IframeCommunication {
  private static instance: IframeCommunication
  private listeners: Record<string, ListenerFn[]> = {}

  private constructor() {
    window.addEventListener('message', ({ data }: MessageEvent<IframeEvent>) => {
      const eventListeners = this.listeners[data.event]
      if (eventListeners) {
        eventListeners.forEach((listener) => {
          listener(data.params)
        })
      }
    })
  }

  static getInstance(): IframeCommunication {
    if (!IframeCommunication.instance) {
      IframeCommunication.instance = new IframeCommunication()
    }
    return IframeCommunication.instance
  }

  sendToParent(msg: IframeEvent) {
    window.parent.postMessage(msg, '*')
  }

  on(event: IframeCommunicationEvent, listener: ListenerFn): void {
    if (!this.listeners[event]) {
      this.listeners[event] = []
    }
    this.listeners[event].push(listener)
  }

  off(event: IframeCommunicationEvent, listener: ListenerFn): void {
    const eventListeners = this.listeners[event]
    if (eventListeners) {
      this.listeners[event] = eventListeners.filter((fn) => fn !== listener)
    }
  }

  remove(event: IframeCommunicationEvent): void {
    const eventListeners = this.listeners[event]
    if (eventListeners) {
      this.listeners[event] = []
    }
  }
}

export const iframeCon = IframeCommunication.getInstance()

export function isLoadedByIframe() {
  return window.self !== window.top
}
