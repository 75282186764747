import { Box, Divider, Heading, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import RuleGameContainer, {
  HexagonHeading,
  NormalParagraph,
  NormalTag,
  UnorderedTextList,
} from './RuleGameContainer.tsx'
import useCustomTranslation from '../../../hooks/useCustomTranslation.tsx'

export default function RuleHBP() {
  const { t } = useCustomTranslation()

  return (
    <RuleGameContainer
      title={
        <>
          {t('ruleGame.bankerPlayer.gameName')}
          <br />
          {t('ruleGame.bankerPlayer.title')}
        </>
      }
    >
      <HexagonHeading heading={t('ruleGame.bankerPlayer.howToDetermine')} />

      <NormalParagraph>{t('ruleGame.bankerPlayer.theLastFiveDigit')}</NormalParagraph>

      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.bankerPlayer.bankerCattleNumber')} <NormalTag>0b9..</NormalTag> ={' '}
            <NormalTag>{t('ruleGame.bankerPlayer.9points')}</NormalTag>
          </>,
          <>
            {t('ruleGame.bankerPlayer.playerCowNumber')} <NormalTag>..52C</NormalTag> ={' '}
            <NormalTag>{t('ruleGame.bankerPlayer.5points')}</NormalTag>
          </>,
        ]}
      />

      <NormalParagraph>{t('ruleGame.bankerPlayer.numberIndicate')}</NormalParagraph>

      <HexagonHeading heading={t('ruleGame.bankerPlayer.howToBet')} />

      <NormalParagraph>{t('ruleGame.bankerPlayer.eachRound')}</NormalParagraph>

      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.bankerPlayer.ifResult')} = <NormalTag>{t('win')}</NormalTag>. {t('ruleGame.bankerPlayer.then')}
          </>,
        ]}
      />

      <NormalParagraph>
        <NormalTag>{t('ruleGame.bankerPlayer.eachRoundWinFormula')}</NormalTag>
      </NormalParagraph>

      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.bankerPlayer.ifResult')} = <NormalTag>{t('lose')}</NormalTag>,{' '}
            {t('ruleGame.bankerPlayer.theSystemWillNot')}
          </>,
        ]}
      />

      <NormalParagraph>{t('ruleGame.bankerPlayer.theAboveWinning')}</NormalParagraph>

      <NormalParagraph>{t('ruleGame.bankerPlayer.example')}</NormalParagraph>

      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.bankerPlayer.yourBetAmount')} <NormalTag>100</NormalTag>
          </>,
          <>
            {t('ruleGame.bankerPlayer.gamePlayYouChoose')} <NormalTag>{t('player')}</NormalTag>
          </>,
          <>
            {t('ruleGame.bankerPlayer.ifTheLotteryHash')} <NormalTag>***a42e7</NormalTag>
          </>,
          <>
            <NormalTag>a4</NormalTag> {t('ruleGame.bankerPlayer.representsBankerPoints')} <NormalTag>4</NormalTag>
          </>,
          <>
            <NormalTag>E7</NormalTag> {t('ruleGame.bankerPlayer.representsPlayerPoints')} <NormalTag>7</NormalTag>
          </>,
          <>
            {t('ruleGame.bankerPlayer.winRate')} <NormalTag>0.98</NormalTag>
          </>,
          <>
            {t('ruleGame.bankerPlayer.matchingGameplay')} <NormalTag>{t('win')}</NormalTag>
          </>,
          <>
            {t('ruleGame.bankerPlayer.theSystemReward')} <NormalTag>100 * 0.98 = 98</NormalTag>
          </>,
        ]}
      />
    </RuleGameContainer>
  )
}
