import useGameId from '@/hooks/useGameId.ts'
import { Text } from '@chakra-ui/react'
import React from 'react'
import useCustomTranslation from '../../../hooks/useCustomTranslation.tsx'
import RuleGameContainer, {
  HexagonHeading,
  NormalParagraph,
  NormalTag,
  UnorderedTextList,
} from './RuleGameContainer.tsx'

export default function RuleHOE() {
  const { t } = useCustomTranslation()
  const gameId = useGameId()
  return (
    <RuleGameContainer
      title={
        <>
          {t(gameId)}
          <br />
          {t('ruleGame.bankerPlayer.title')}
        </>
      }
    >
      <HexagonHeading heading={t('ruleGame.oddeven.howToDetermine')} />
      <NormalParagraph>{t('ruleGame.oddeven.paragraph1')}</NormalParagraph>
      <NormalParagraph>{t('ruleGame.oddeven.paragraph2')}</NormalParagraph>

      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.oddeven.listItem1')}
            <NormalTag>
              ******59e
              <Text as="span" color={'#c3fb04'}>
                1
              </Text>
            </NormalTag>{' '}
            = <NormalTag>{t('ruleGame.oddeven.cattleNine')}</NormalTag>
          </>,
          <>
            {t('ruleGame.oddeven.listItem2')}
            <NormalTag>
              ******59e{' '}
              <Text as="span" color={'#c3fb04'}>
                2
              </Text>
            </NormalTag>{' '}
            = <NormalTag>{t('ruleGame.oddeven.cowSeven')}</NormalTag>
          </>,
        ]}
      />
      <NormalParagraph>{t('ruleGame.oddeven.rebateAmount')}</NormalParagraph>
      <NormalParagraph>{t('ruleGame.oddeven.paragraph3')}</NormalParagraph>

      <HexagonHeading heading={t('ruleGame.oddeven.example')} />
      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.oddeven.exampleItems.betAmount')}
            <NormalTag>
              <Text as="span" color={'#c3fb04'}>
                100
              </Text>
            </NormalTag>
            <Text>
              {t('ruleGame.oddeven.exampleItems.gameplayChoice')}
              <NormalTag>
                <Text as="span" color={'#c3fb04'}>
                  {t('ruleGame.oddeven.result')}
                </Text>
              </NormalTag>
            </Text>
          </>,
          <>
            {t('ruleGame.oddeven.exampleItems.lotteryHash')}
            <NormalTag>
              ******59e
              <Text as="span" color={'#c3fb04'}>
                9
              </Text>
            </NormalTag>
            <Text>
              {t('ruleGame.oddeven.exampleItems.lotteryResult')} <NormalTag>9</NormalTag>
            </Text>
          </>,
          <>
            {t('ruleGame.oddeven.exampleItems.result')}
            <NormalTag>
              <Text as="span" color={'#c3fb04'}>
                {t('ruleGame.oddeven.result')}
              </Text>
            </NormalTag>
            <Text>
              {t('ruleGame.oddeven.exampleItems.matchResult')} <NormalTag color={'#c3fb04'}>{t('WIN')}</NormalTag>
            </Text>
          </>,
          <>
            {t('ruleGame.oddeven.exampleItems.rewardAmount')}
            <NormalTag>
              100 * 1.95 =
              <Text as="span" color={'#c3fb04'}>
                195
              </Text>
            </NormalTag>
          </>,
        ]}
      />
    </RuleGameContainer>
  )
}
