import gameFetcher from '../../../libs/gameFetcher'
import { TimeResponse } from '../entities/common.entity'
import { GameConfig } from '../entities/game.entity'
import { BetType } from '../entities/order.entity'
import { BaseGetManyResponse } from '../entities/response.entity'

export class GameService {
  static async getTrends(gameId: string): Promise<BaseGetManyResponse<BetType>> {
    const resp = await gameFetcher.get(`/${gameId}/game/result-history`)
    const { data, total } = resp.data as BaseGetManyResponse<BetType>

    //xử lý data các game ở trend chỗ này [big / small]
    return {
      data: data.reverse(),
      total,
    }
  }
  static async getServerTime(): Promise<TimeResponse> {
    const startTime = Date.now()
    const resp = await gameFetcher.get('/common/time')
    const endTime = Date.now()
    const latency = endTime - startTime
    const { time } = resp.data as TimeResponse
    const diff = Math.round(time - endTime + latency / 2)
    return { time, latency, diff }
  }
  static async getConfig(gameID: string): Promise<GameConfig> {
    const resp = await gameFetcher.get(`/${gameID}/game/config`)
    return resp.data as GameConfig
  }
}
