export interface ErrorOptions {
  message: string
  code?: string
  meta?: Record<string, any>
  mute?: boolean
}

export declare type PopupErrorHandler = (payload: ErrorOptions) => any

let _popupError: PopupErrorHandler

export function setPopupErrorHander(handler: PopupErrorHandler) {
  _popupError = handler
}
export function popupError(message: ErrorOptions) {
  _popupError(message)
}
