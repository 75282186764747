import React from 'react'
import AuthGame from '../../components/AuthGame'
import { Stack, Text } from '@chakra-ui/react'
import ControlSection from '../../components/hash-game/ControlSection'
import Wallet from '../../components/hash-game/Wallet'
import { bgColorControl } from '../../const/color'
import CountdownLucky from '../../components/lucky/Countdown'
import BettingHLUCKY from '../../components/lucky/Betting'

export default function MainGameLucky() {
  return (
    <AuthGame>
      <Wallet />
      <ControlSection />
      <Stack backgroundColor={bgColorControl} borderBottomRadius={12}>
        <CountdownLucky />
        <BettingHLUCKY />
      </Stack>
    </AuthGame>
  )
}
