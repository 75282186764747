import MqttContext from '@hooks/context'
import { IMqttContext as Context, IMessage } from '@hooks/types'
import { IClientSubscribeOptions, IPublishPacket } from 'mqtt'
import { useCallback, useContext, useEffect, useState } from 'react'

const SEPARATOR = '/'
const SINGLE = '+'
const ALL = '#'

function matches(pattern: any, topic: any) {
  const patternSegments = pattern.split(SEPARATOR)
  const topicSegments = topic.split(SEPARATOR)

  const patternLength = patternSegments.length
  const topicLength = topicSegments.length
  const lastIndex = patternLength - 1

  for (let i = 0; i < patternLength; i++) {
    const currentPattern = patternSegments[i]
    const patternChar = currentPattern[0]
    const currentTopic = topicSegments[i]

    if (!currentTopic && !currentPattern) continue

    if (!currentTopic && currentPattern !== ALL) return false

    // Only allow # at end
    if (patternChar === ALL) return i === lastIndex
    if (patternChar !== SINGLE && currentPattern !== currentTopic) return false
  }

  return patternLength === topicLength
}

export default function useSubscription(
  topic: string | string[],
  options: IClientSubscribeOptions = {} as IClientSubscribeOptions,
) {
  const { client, connectionStatus, parserMethod } = useContext<Context>(MqttContext)

  const [message, setMessage] = useState<IMessage | undefined>(undefined)

  const subscribe = useCallback(async () => {
    client?.subscribe(topic, options)
  }, [client, options, topic])

  const callback = useCallback(
    (receivedTopic: string, receivedMessage: any, config: IPublishPacket) => {
      if ([topic].flat().some((rTopic) => matches(rTopic, receivedTopic))) {
        setMessage({
          topic: receivedTopic,
          message: parserMethod?.(receivedMessage) || receivedMessage.toString(),
          config,
        })
      }
    },
    [parserMethod, topic],
  )

  useEffect(() => {
    if (client?.connected) {
      client.setMaxListeners(100)
      subscribe()
      client.on('message', callback)
    }
    return () => {
      client?.off('message', callback)
    }
  }, [callback, client, subscribe])

  return {
    client,
    topic,
    message,
    connectionStatus,
  }
}
