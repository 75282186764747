import useGameId from '@/hooks/useGameId.ts'
import { Button, Flex, Image, Stack, Text } from '@chakra-ui/react'
import { useAppSelector } from '@store'
import { selectLastLotteryHash, selectLastResult } from '@store/modules/game.slice'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tooltip as CGameTooltip } from 'react-tooltip'
import { textGray } from '../../const/color'
import useCustomTranslation from '../../hooks/useCustomTranslation.tsx'
import { AppDrawer } from '../common/AppDrawer.tsx'
import GamePlayAndVerification from '../hash-game/infos/GamePlayAndVerification.tsx'
import { LotteryHash } from '../hash-game/LotteryHash.tsx'

export default function Wallet() {
  const location = useLocation()
  const navigate = useNavigate()
  const [popup, setPopup] = useState('')
  const lastLotteryHash = useAppSelector(selectLastLotteryHash)
  const lastResult = useAppSelector(selectLastResult)
  const { t } = useCustomTranslation()
  const gameId = useGameId()

  const closePopup = () => {
    setPopup('')
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('param')
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true })
  }

  return (
    <Flex
      background="#171718"
      fontWeight={400}
      p={'5px 7px 5px 12px'}
      borderRadius={8}
      gap={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack color="#9898a1">
        <Text width="77px" fontSize={'10px'} data-tooltip-id="my-tooltip-1">
          {t(`previousLottery_${gameId}`)}
          <br />
          {t('resultHash')}
          <Image ml={1} w={3} src="/images/info.svg" display="inline-block" />
        </Text>

        <CGameTooltip
          style={{ zIndex: 10000, maxWidth: 200, textAlign: 'center', lineHeight: 1.2 }}
          id="my-tooltip-1"
          place="bottom"
          content={t('notification.info')}
        />
      </Stack>

      <Stack justifyContent="center" textAlign="center" p={0} m={0}>
        <Flex fontWeight={700} fontSize={'13px'} p={0} m={0} justifyContent="center">
          <LotteryHash text={lastLotteryHash} type={'middleEllipsis'} prefixLength={9} suffixLength={8} />
          <Text textTransform={'uppercase'} color="#d5fe47">
            {' '}
            &nbsp;&nbsp;&nbsp;&nbsp; {!lastResult ? '' : t(`${lastResult}`)}
          </Text>
        </Flex>
        <Text fontSize={'10px'} mt={-3} fontWeight={300} color={textGray}>
          {t('theResultsAre')} <span style={{ color: '#d5fe47' }}>{t('verifiable')}</span>, {t('blockchainIs')}
          <span style={{ color: '#d5fe47' }}>{t('fairer')}</span>
        </Text>
      </Stack>
      <Button
        fontSize={{
          base: '11px',
        }}
        p="0 8px"
        aria-label="verify"
        aria-labelledby="verify"
        // width="56px"
        width="auto"
        height="25px"
        fontWeight={600}
        textTransform="uppercase"
        letterSpacing={1}
        backgroundColor="#29292f"
        color="white"
        transition="0.5s"
        _hover={{ backgroundColor: '#d5fe47', color: '#242429' }}
        onClick={() => {
          setPopup('Verification')

          const searchParams = new URLSearchParams(location.search)
          searchParams.set('param', 'Verification')
          navigate(`?${searchParams.toString()}`, { replace: true })
        }}
      >
        <Text mt={0.5}>{t('verify')}</Text>
      </Button>

      <AppDrawer isOpen={'Verification' === popup} onClose={closePopup} overlay={true}>
        <GamePlayAndVerification />
      </AppDrawer>
    </Flex>
  )
}
