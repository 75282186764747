import { Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useCustomTranslation from '../../hooks/useCustomTranslation'

export function LotteryHashHLUCKY(props: {
  text: string
  type?: 'full' | 'middleEllipsis' | 'suffixOnly'
  prefixLength?: number // in case of type!=full
  suffixLength?: number // in case of type!=full
  children?: any
}) {
  const { t } = useCustomTranslation()
  const { type, text: hash, prefixLength, suffixLength, children, ...rest } = props
  if (!hash) return null

  let h = hash
  if (type === 'middleEllipsis') {
    h = hash.substring(0, prefixLength) + '...' + hash.substring(hash.length - (suffixLength ?? 0))
  } else if (type === 'suffixOnly') {
    h = '...' + hash.substring(hash.length - (suffixLength ?? 0))
  }

  const { prefix, digit, suffix } = parsePrizeHash(h)

  return (
    <Text {...rest}>
      {prefix}
      <span style={{ color: '#d5fe47' }}>{digit}</span>
      {suffix}
      {children}
    </Text>
  )
}

export function parsePrizeHash(text: string) {
  if (!text) return null
  const prefix = text.slice(0, -2)
  const digit = text.slice(-2)
  const suffix = ''
  return {
    prefix,
    digit,
    suffix,
  }
}
