import { Button, ChakraProvider, Flex, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import 'react-tooltip/dist/react-tooltip.css'
import './App.sass'
import LayoutFullWidth from './components/common/LayoutFullWidth'
import { CustomTheme, ToastOptions } from './const/custom-theme'
import { GameUrlMap } from './const/game-type.ts'
import { setSrcReferer } from './hooks/useBackToPreviousOrigin.ts'
import './i18n'
import MainGameBankerPlayer from './pages/banker-player/MainGame.tsx'
import Error from './pages/Error.tsx'
import BetDetail from './pages/hash-game/BetDetail.tsx'
import MainGameHBS from './pages/hash-game/MainGame'
import MainGameLucky from './pages/lucky-hash/MainGame.tsx'
import MaintenancePage from './pages/Maintenance.tsx'
import MainGameNiuNiu from './pages/niuniu/MainGame.tsx'
import MainGameHOE from './pages/odd-even/MainGame.tsx'
import VersionInfoPage from './pages/version-info.tsx'
import { persistor, store } from './redux/store/index.ts'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Configs } from './const/configs.ts'
import useCustomToast, { CustomToastOptions } from './hooks/useCustomToast.ts'
import useCustomTranslation from './hooks/useCustomTranslation.tsx'
import { usePageTracking } from './hooks/usePageTracking.ts'
import { GA } from './utils/analytics.ts'
import { locationCheck } from './utils/helper.ts'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10000,
    },
    mutations: {
      onError: () => {
        // Xử lý lỗi mutations ở đây
      },
    },
  },
})

const App: React.FC = () => {
  const gameIdDefault = 'H_BS'
  const { t } = useCustomTranslation()
  const { showToastThrottle, closeToast } = useCustomToast()

  const switchDomainToastConfig = ({
    title,
    description,
    newHref,
    goToText,
  }: {
    title: string | React.ReactNode
    description: string
    newHref: string
    goToText: string
  }): CustomToastOptions & any => {
    return {
      title,
      description: (
        <>
          <Text mt="5px" mb="10px">
            {description}
          </Text>
          <Flex gap="10px" justifyContent="center" alignItems="center">
            <Button
              background="none"
              textDecoration="underline"
              padding="0"
              type="button"
              _hover={{
                background: 'none',
              }}
              onClick={() => {
                localStorage.setItem('notShowSwitchDomainPopupAgain', 'true')
                closeToast()
              }}
            >
              {t('switchDomainPopup.dontShow')}
            </Button>
            <Button
              background="#29292f"
              color="#ffffff"
              _hover={{
                background: '#d5fe47',
                color: '#242429',
              }}
              onClick={() => {
                window.location.href = newHref
              }}
            >
              {goToText}
            </Button>
          </Flex>
        </>
      ),
      status: 'info',
      isClosable: true,
      duration: null,
      containerStyle: {
        width: '60px',
        paddingBlock: '0px',
        className: 'my-toast-stack',
        background: '#ffffff !important',
        borderRadius: '14px',
      },
    }
  }

  useEffect(() => {
    GA.init(Configs.trackingId)
    setSrcReferer()
  }, [])

  useEffect(() => {
    if (localStorage.getItem('notShowSwitchDomainPopupAgain') === 'true') return

    let isMounted = true

    ;(async () => {
      const href = window.location.href
      const origin = window.location.origin
      const cnOrigin = import.meta.env.VITE_CN_DOMAIN
      const isCnDomain = origin === cnOrigin
      const isUserFromCn = (await locationCheck()) === 'CN'

      if (isUserFromCn && !isCnDomain) {
        const newHref = href.replace(origin, cnOrigin)

        if (isMounted) {
          setTimeout(() => {
            showToastThrottle(
              switchDomainToastConfig({
                title: t('switchDomainPopup.cn.title'),
                description: t('switchDomainPopup.cn.desc'),
                goToText: t('switchDomainPopup.cn.goCN'),
                newHref,
              }),
            )
          }, 1000)
        }
      }

      if (!isUserFromCn && isCnDomain) {
        const globalOrigin = import.meta.env.VITE_GLOBAL_DOMAIN
        const newHref = href.replace(origin, globalOrigin)

        if (isMounted) {
          setTimeout(() => {
            showToastThrottle(
              switchDomainToastConfig({
                title: t('switchDomainPopup.global.title'),
                description: t('switchDomainPopup.global.desc'),
                goToText: t('switchDomainPopup.global.goCN'),
                newHref,
              }),
            )
          }, 1000)
        }
      }
    })()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={CustomTheme} toastOptions={ToastOptions}>
              <LayoutFullWidth>
                <div style={{ width: '100%' }}>
                  <Routes>
                    <Route path="/" element={<Navigate to={`/${gameIdDefault}`} />} />
                    <Route path={`/${GameUrlMap.H_BS}`} element={wrapper(<MainGameHBS />)} />
                    <Route path={`/${GameUrlMap.H_BP}`} element={wrapper(<MainGameBankerPlayer />)} />
                    <Route path={`/${GameUrlMap.H_OE}`} element={wrapper(<MainGameHOE />)} />
                    <Route path={`/${GameUrlMap.H_NIUNIU}`} element={wrapper(<MainGameNiuNiu />)} />
                    <Route path={`/${GameUrlMap.H_LUCKY}`} element={wrapper(<MainGameLucky />)} />
                    <Route path="/maintenance" element={wrapper(<MaintenancePage />)} />
                    <Route path="/bet-detail" element={wrapper(<BetDetail />)} />
                    <Route path={`/:${gameIdDefault}/error`} element={wrapper(<Error />)} />
                    <Route path="/version" element={wrapper(<VersionInfoPage />)} />
                  </Routes>
                </div>
              </LayoutFullWidth>
            </ChakraProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  )
}
function PageWrapper({ children }: any) {
  usePageTracking()

  return children
}

function wrapper(children: any) {
  return <PageWrapper>{children}</PageWrapper>
}
export default App
