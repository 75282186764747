import useGameId from '@/hooks/useGameId'
import { selectIsLoadingAction } from '@/redux/store/modules/common.slice'
import { Box, Flex, Image, Spinner, Stack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { isLastGameLose, isLastGameWinner } from '@store/common/selectors'
import { selectIsLogged } from '@store/modules/auth.slice'
import { getMyOrders, selectCreateOrderStatus, selectMyOrder, selectTotal } from '@store/modules/order.slice'
import { isEmpty } from 'lodash'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import React, { useEffect, useState } from 'react'
import useCustomTranslation from '../../hooks/useCustomTranslation'
import BetDetail from './infos/BetDetail'

export default function ListResult({ noPaging = false }: { noPaging: boolean }) {
  const { t } = useCustomTranslation()
  const dispatch = useAppDispatch()
  const limit = 10
  const [page, setPage] = useState(1)
  const gameId = useGameId()
  const query = { page, gameId }
  const ids = useAppSelector(selectMyOrder(query))
  const [orderHistory, setOrders] = useState<string[]>([])

  useEffect(() => {
    if (!isEmpty(ids)) {
      setOrders(ids)
    }
  }, [ids])

  const total = useAppSelector(selectTotal)

  const isWinner = useAppSelector(isLastGameWinner)
  const isLoser = useAppSelector(isLastGameLose)
  const isLogged = useAppSelector(selectIsLogged)

  const createOrderStatus = useAppSelector(selectCreateOrderStatus)
  const isLoading = useAppSelector(selectIsLoadingAction(getMyOrders.pending))

  useEffect(() => {
    if (isLogged) {
      dispatch(getMyOrders({ gameId: gameId, page }))
    }
  }, [page, isWinner, isLoser, createOrderStatus])

  const changePage = (page: number) => {
    setPage(page)
  }

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Spinner />
        </Box>
      )}
      <Stack
        sx={{
          opacity: isLoading ? '0.3' : '1',
        }}
      >
        {orderHistory?.length > 0 ? (
          orderHistory.map((id) => <BetDetail key={id} id={id} />)
        ) : (
          <Flex
            w="100%"
            height="200px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color="#3d3d43"
            gap={'11px'}
          >
            <Box>
              <Image w={'40px'} src="/images/norecord.webp" />
            </Box>
            <Box color={'#4b4b4d'} fontSize={'13px'}>
              {t('notification.youHavenorecordsYet')}
            </Box>
          </Flex>
        )}
        {noPaging === false && (
          <Stack alignItems="center">
            {orderHistory?.length > 0 && (
              <Flex direction="row" align="center" justify="center" mt={4}>
                <Pagination className="paging" onChange={changePage} current={page} total={total} pageSize={limit} />
              </Flex>
            )}
          </Stack>
        )}
      </Stack>
    </>
  )
}
