import { useTranslation } from 'react-i18next'

const useCustomTranslation = () => {
  const { t, i18n } = useTranslation()

  // const t = (a: string, b?: any) => a
  // const i18n = {}

  const translate = (key: string, options?: any): string => {
    const defaultOptions = {
      defaultValue: t('notification.unknown'),
    }
    const translateOptions = options ? { ...options, ...defaultOptions } : defaultOptions

    return `${t(key, translateOptions)}`
  }

  return { t: translate, i18n }
}

export default useCustomTranslation
