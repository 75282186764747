import axios from 'axios'

export function extractGameId(path?: string) {
  if (!path) return ''
  return path.replace(/^\/|\/$/g, '') || ''
}

export const locationCheck = async () => {
  try {
    const cloudflareRes = await axios.get('https://www.cloudflare.com/cdn-cgi/trace')
    const data = cloudflareRes?.data
      ?.trim()
      ?.split('\n')
      ?.reduce((obj: Record<string, string>, value: string) => {
        const pair = value.split('=')
        obj[pair[0]] = pair[1]
        return obj
      }, {})

    return data?.loc
  } catch (error) {
    return undefined
  }
}
