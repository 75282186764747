import { GA } from '@/utils/analytics'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function usePageTracking() {
  const { pathname, search } = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(search)
    params.delete('agency_id')
    params.delete('session_token')
    params.delete('access_token')
    const page = search ? `${pathname}?${params}` : pathname
    GA.trackPageView(page)
  }, [pathname, search])
}
