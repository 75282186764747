import { Container } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { bgColorApp } from '../../const/color.ts'

export default function LayoutFullWidth({ children }: { children: ReactNode }) {
  return (
    <Container
      p={'7px'}
      w={'100%'}
      centerContent
      bgColor={bgColorApp}
    >
      {children}
    </Container>
  )
}
