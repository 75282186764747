import { extractGameId } from '@/utils/helper'
import { UseToastOptions } from '@chakra-ui/react'
import React from 'react'

export interface CustomToastOptions extends UseToastOptions {
  title: React.ReactNode
  description?: React.ReactNode
  isClosable?: boolean
  duration?: number | null
  status?: 'success' | 'error' | 'warning' | 'info'
}

const useGameId = () => {
  const currentPath = window.location.pathname
  return extractGameId(currentPath)
}

export default useGameId
