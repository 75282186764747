export async function getFingerprint(format: 'hex' | 'buffer' = 'hex') {
  // Collect browser information
  const userAgent = navigator.userAgent
  const screenResolution = `${screen.width}x${screen.height}`
  const colorDepth = screen.colorDepth
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const browserVersion = navigator.appVersion
  const language = navigator.language

  const browserSettingState = {
    userAgent,
    screenResolution,
    colorDepth,
    timezone,
    browserVersion,
    language,
  }

  // Generate fingerprint
  const hashBuffer = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(JSON.stringify(browserSettingState)),
  )
  if (format === 'buffer') {
    return hashBuffer
  }

  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export async function endCode(value: string) {
  const secret = (await getFingerprint('buffer')) as ArrayBuffer
  const encoder = new TextEncoder()
  const data = encoder.encode(value)
  const key = await crypto.subtle.importKey('raw', secret, 'AES-GCM', false, ['encrypt'])
  const endcodedData = await crypto.subtle.encrypt({ name: 'AES-GCM', iv: new Uint8Array(12) }, key, data)
  const hashArray = Array.from(new Uint8Array(endcodedData))
  const hexStr = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

  return hexStr
}

export async function decode(hexStr: string) {
  const secret = (await getFingerprint('buffer')) as ArrayBuffer
  const key = await crypto.subtle.importKey('raw', secret, 'AES-GCM', false, ['decrypt'])
  const hashArray = hexStr.match(/.{1,2}/g)?.map((byte) => parseInt(byte, 16))
  if (!hashArray) {
    throw new Error('Invalid hex string')
  }

  const data = new Uint8Array(hashArray).buffer
  const decodedData = await crypto.subtle.decrypt({ name: 'AES-GCM', iv: new Uint8Array(12) }, key, data)
  const decoder = new TextDecoder()
  return decoder.decode(decodedData)
}
