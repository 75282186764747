// src/features/positionSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

export interface PositionState {
  positions: { [key: number]: { x: number; y: number } }
}

const initialState: PositionState = {
  positions: {},
}

const positionSlice = createSlice({
  name: 'position',
  initialState,
  reducers: {
    setPosition: (state, action: PayloadAction<{ index: number; x: number; y: number }>) => {
      const { index, x, y } = action.payload
      state.positions[index] = { x, y }
    },
  },
})

export const { setPosition } = positionSlice.actions
export const selectPositions = (state: RootState) => state.position.positions
export default positionSlice
