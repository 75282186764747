import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import gameSlice from '@/redux/store/modules/game.slice'
import React, { useEffect } from 'react'

export default function GameConfigSubscription() {
  const gameId = useGameId()
  const dispatch = useAppDispatch()

  const _message = useSubscription(Topics.configGame(gameId))
  const message = _message?.message?.message

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '')
      dispatch(gameSlice.actions.updateConfig(data?.config))
    } catch (error) {
      console.warn('GameConfigSubscription error: ', error)
    }
  }, [message])

  return <></>
}
