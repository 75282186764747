import useGameId from '@/hooks/useGameId.ts'
import { Box, Flex, Image, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useAppSelector } from '@store'
import { selectTopWinner, selectTrendsBoard } from '@store/modules/game.slice'
import flatMap from 'lodash/flatMap'
import React, { useMemo } from 'react'
import { textGray } from '../../const/color'
import { currency } from '../../const/currency.tsx'
import { gameConfigs, GameUrlMap } from '../../const/game-type.ts'
import useCustomTranslation from '../../hooks/useCustomTranslation.tsx'
import { money } from '../../utils/number.ts'
import ChessBoard from '../UI/ChessBoard'
import BettingTable from '../UI/Table.tsx'
import Chess from '../common/Chess.tsx'

function RenderItem() {
  return (
    <Stack>
      <ChessBoard />
    </Stack>
  )
}

function RenderTable() {
  const { t } = useCustomTranslation()
  const visibleRows = useAppSelector(selectTopWinner)
  const allTopWinners = visibleRows.flatMap((row: any) => row.topWinners)

  const columns = useMemo(
    () => [
      {
        title: t('prize.game'),
        dataIndex: 'gameId',
        key: 'gameId',
        render: (row: any) => <>{row ? t(`name-games.${row?.gameId}`) : ''}</>,
      },
      {
        title: t('prize.player'),
        dataIndex: 'userId',
        key: 'userId',
        render: (row: any) => <>...{row ? row?.userId?.substring(row.userId.length - 6) : 'Unknown'}</>,
      },
      {
        title: t('prize.amount'),
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
        render: (row: any) => (
          <Flex gap={1} alignItems="center" justifyContent={'center'}>
            <Text position="relative" bottom="-1px">
              {row ? money(row?.amount || 0) : ''}
            </Text>
            {currency.find((item) => item.name === row.currency)?.icon}
          </Flex>
        ),
      },
      {
        title: t('result'),
        dataIndex: 'win',
        key: 'win',
        align: 'center',
        render: (row: any) => (
          <Flex gap={1} alignItems="center" justifyContent={'center'}>
            {row && <Chess type={row?.bet} width="20px" height="20px" />}
          </Flex>
        ),
      },
      {
        title: t('prize.win'),
        dataIndex: 'winAmount',
        key: 'winAmount',
        render: (row: any) => (
          <Flex gap={1} justifyContent="center" alignItems={'center'}>
            {money(row?.winAmount || 0)}
            {currency.find((item) => item.name === row.currency)?.icon}
          </Flex>
        ),
      },
    ],
    [t],
  )

  return (
    <Stack w="100%" overflow="hidden" gap={0}>
      <BettingTable columns={columns} datasource={allTopWinners} height={'212px'} />
    </Stack>
  )
}

export default function Result() {
  const { t } = useCustomTranslation()
  const board = useAppSelector(selectTrendsBoard)
  const gameID = useGameId()

  const types = useMemo(() => {
    return gameConfigs[gameID]?.type || []
  }, [gameID])

  const counts = useMemo(() => {
    const flatBoard = flatMap(board)
    return types.map((type) => flatBoard.filter((x) => x === type).length)
  }, [board, types])

  return (
    <Stack marginTop="4px">
      <Tabs variant="enclosed" m={0} p={0}>
        <TabList p={0} position="relative" border="none" mb="-2px">
          <Image
            w={'100%'}
            // ml={-0.5}
            // h={'50%'}
            aspectRatio={9 / 1}
            src="/images/Panel.svg"
            alt=""
          />
          <Flex w="100%" transform={'translate(-50%, -50%)'} top={'50%'} left={'50%'} position={'absolute'}>
            <Flex gap="28px" p={2} pl="14px" alignItems="center" fontWeight={500}>
              <Tab
                p={0}
                fontSize={'15px'}
                color="#5f5f65"
                // fontWeight={400}
                border="none"
                borderBottom="2px solid transparent"
                lineHeight={1.4}
                _selected={{
                  // fontWeight: 400,
                  // border: 'none',
                  borderColor: '#c3fb04',
                  color: 'white',
                }}
              >
                <Box px="2px">{t('trend')}</Box>
              </Tab>
              <Tab
                p={0}
                color="#5f5f65"
                // fontWeight={400}
                fontSize={'15px'}
                // letterSpacing="-0.6px"
                border="none"
                borderBottom="2px solid transparent"
                lineHeight={1.4}
                _selected={{
                  color: 'white',
                  // fontWeight: 400,
                  // border: 'none',
                  borderColor: '#c3fb04',
                }}
              >
                <Box px="2px">{t('lastersAwards')}</Box>
              </Tab>
            </Flex>
            <Flex
              position="absolute"
              alignItems="center"
              // left="59%"
              right={0}
              width="43%"
              top={'40%'}
              transform="translateY(-50%)"
              justifyContent="center"
              // pl={2}
              // pr="14px"
              gap={gameID === GameUrlMap.H_NIUNIU ? '16px' : '20px'}
              fontWeight={500}
            >
              {types.map((type, i) => (
                <Flex key={i} gap={1.5} alignItems="center">
                  <Chess type={type} width="12.89px" height="12.89px" />
                  <Text textTransform="capitalize" fontSize="12px" lineHeight={'12px'} color={textGray}>
                    {types.length >= 3 ? `` : `${t(type).toLowerCase()}:`} {counts[i] || 0}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </TabList>
        <TabPanels>
          <TabPanel paddingInline={0} paddingBlock={0}>
            <RenderItem />
          </TabPanel>
          <TabPanel p={0}>
            <RenderTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}
