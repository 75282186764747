import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { useAppSelector } from '@store/index.ts'
import { selectOrderHistoryNoPaging } from '@store/modules/order.slice.ts'
import React, { useState } from 'react'
import { FaAngleRight } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import useCustomTranslation from '../../hooks/useCustomTranslation.tsx'
import { AppDrawer } from '../common/AppDrawer.tsx'
import ListResult from './ListRecord'
import ListRecordHomePage from './ListRecordNoPaging'

export default function Record() {
  const { t } = useCustomTranslation()
  const [popup, setPopup] = useState('')
  const navigate = useNavigate()
  const orderHistory = useAppSelector(selectOrderHistoryNoPaging)

  const closePopup = () => {
    setPopup('')
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('param')
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true })
  }
  return (
    <Stack marginTop={1} gap="14px">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid #1a1a1a"
        boxShadow="0px 1px 0px 0px #2b2b2f"
        pb="6px"
      >
        <Text fontSize="15px" color="#E8E8EA" textStyle="semibold">
          {t('bettingRecord')}
        </Text>
        <Button
          aria-label="All"
          aria-labelledby="All"
          _focus={{ bg: 'none' }}
          _hover={{ bg: 'none' }}
          rightIcon={<FaAngleRight style={{ marginBottom: '1px' }} />}
          bg="none"
          color="#56555B"
          onClick={() => {
            setPopup('BettingRecord')
            const searchParams = new URLSearchParams(location.search)
            searchParams.set('param', 'BettingRecord')
            navigate(`?${searchParams.toString()}`, { replace: true })
          }}
          p={0}
          minWidth="auto"
          height="auto"
          fontSize="12px"
          // position="relative"
          // top="3px"
        >
          {t('all')}
        </Button>
      </Flex>
      <Stack>
        <ListRecordHomePage />
        {orderHistory.length && (
          <Stack>
            <Text
              marginBlock={4}
              marginInline={'auto'}
              w={'70px'}
              color="#56555B"
              textAlign={'center'}
              height="auto"
              fontSize="12px"
              cursor={'pointer'}
              onClick={() => {
                setPopup('BettingRecord')
                const searchParams = new URLSearchParams(location.search)
                searchParams.set('param', 'BettingRecord')
                navigate(`?${searchParams.toString()}`, { replace: true })
              }}
            >
              {t('viewmore')}
            </Text>
          </Stack>
        )}
      </Stack>

      <AppDrawer
        title={t(`menu.bettingListing`)}
        isOpen={'BettingRecord' === popup}
        onClose={closePopup}
        overlay={true}
      >
        <Box p={4}>
          <ListResult noPaging={false} />
        </Box>
      </AppDrawer>
    </Stack>
  )
}
