import { jwtDecode } from 'jwt-decode'
import endpoint from '../../../const/endpoint'
import { fetchFn } from '../../../libs/apiHubFetcher'
import { AuthModel, Player } from '../entities/auth.entity'

export class AuthService {
  async login(agencyId: string, sessionToken: string) {
    const resp = await fetchFn<AuthModel>('POST', `${endpoint.LOGIN_VIA_API_HUB}`, {
      session_token: sessionToken,
      agency_id: agencyId,
    })

    const auth = resp.data.data
    auth.player.agency_id = agencyId
    return auth
  }
  async loadPlayerInfo() {
    const response = await fetchFn<Player>('GET', endpoint.PLAYER_INFO)
    return response.data.data
  }

  isTokenExpired(token: string) {
    if (!token) {
      return true
    }

    try {
      const decoded = jwtDecode(token)
      const currentTime = Date.now() / 1000

      return (decoded.exp || 0) < currentTime
    } catch (error) {
      console.error('Error decoding token:', error)
      return true
    }
  }
}

export default new AuthService()
