import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { forEach } from 'lodash'
import { RootState } from '..'
import { LastGameDetail } from '../entities/game.entity'
import { BetType, OrderDetail, OrderRequest } from '../entities/order.entity'
import { OrderService } from '../services/order.service'
import { createThunk } from './common'
import { selectAmountResultAround, selectCurrentLotteryResultHash, selectGameId } from './game.slice'
import { selectCurrency, selectWallet } from './wallet.slice'

export interface OrderState {
  bet: BetType
  amount: number
  win_amount: number
  createOrderStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  createOrderError: string | null
  myOrderIds: Record<string, string[]>
  orderDetails: Record<string, OrderDetail>
  totals: Record<string, number>
}

const initialState: OrderState = {
  bet: 'BIG',
  amount: 0,
  win_amount: 0,
  createOrderStatus: 'idle',
  createOrderError: null,
  myOrderIds: {},
  orderDetails: {},
  totals: {},
}

export const createOrder = createThunk('order/createOrder', async (_: void, thunkApi) => {
  const { getState } = thunkApi
  const state = getState() as RootState
  const amount = selectOrderAmount(state)
  const bet = selectOrderBetType(state) as BetType
  const lotteryResultHash = selectCurrentLotteryResultHash(state) || ''
  const win_amount = selectAmountResultAround(state) || 0
  const currency = selectCurrency(state)
  const walletId = selectWallet(state).id

  const order: OrderRequest = {
    amount,
    bet,
    lotteryResultHash,
    win_amount,
    currency,
    walletId,
  }

  return OrderService.createOrder(state.game.gameId, order)
})

export type SearchOrderQuery = {
  gameId: string
  page?: number
  limit?: number
}

function getMyOrderKey(query: SearchOrderQuery) {
  const { gameId, page, limit } = query || {}
  return `${gameId}|${page || 1}|${limit || LIMIT_PER_PAGE}`
}

const LIMIT_PER_PAGE = 10

export const getMyOrders = createThunk('orders/getMyOrders', async (input: SearchOrderQuery, thunkApi) => {
  const { gameId, page, limit } = input || {}
  const resp = await OrderService.getOrderHistory(gameId, page || 1, limit || LIMIT_PER_PAGE)
  return resp
})
export const refreshMyOrders = createThunk('orders/refreshMyOrders', async (input: SearchOrderQuery) => {
  const resp = await OrderService.getOrderHistory(input.gameId, 0, LIMIT_PER_PAGE)
  return resp
})

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    lastGameFinished: (state, action: PayloadAction<LastGameDetail>) => {
      const { result, lotteryResultHash } = action.payload.last || {}
      if (!result) return
      forEach(state.orderDetails, (item) => {
        if (item.lotteryResultHash != lotteryResultHash) return
        item.result = result
      })
    },
    betTypeUpdated: (state, action: PayloadAction<BetType>) => {
      state.bet = action.payload
    },
    amountUpdated: (state, action: PayloadAction<number>) => {
      state.amount = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyOrders.fulfilled, (state, action) => {
        const query = action.meta.arg
        const key = getMyOrderKey(query)
        const orders = action.payload.data
        const total = action.payload.total
        if (total) {
          state.totals[key] = total
        }
        const ids = orders.map((order) => order.id)
        state.myOrderIds[key] = ids

        orders.forEach((order) => {
          const old = state.orderDetails[order.id] || {}
          state.orderDetails[order.id] = {
            ...old,
            ...order,
          }
        })
      })
      .addCase(refreshMyOrders.fulfilled, (state, action) => {
        const query = action.meta.arg
        const key = getMyOrderKey(query)
        const orders = action.payload.data
        const total = action.payload.total
        if (total) {
          state.totals[query.gameId] = total
        }
        const ids = orders.map((order) => order.id)
        state.myOrderIds[key] = ids

        forEach(orders, (order) => {
          const old = state.orderDetails[order.id] || {}
          state.orderDetails[order.id] = {
            ...old,
            ...order,
          }
        })
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.createOrderStatus = 'succeeded'
        state.createOrderError = null
        const order = action.payload
        const key = getMyOrderKey({
          gameId: order.game_type || '',
        })
        const orderIds = state.myOrderIds[key] || []
        orderIds.unshift(order.id)
        state.myOrderIds[key] = orderIds
        state.orderDetails[order.id] = order
      })
      .addCase(createOrder.pending, (state) => {
        state.createOrderStatus = 'loading'
        state.createOrderError = null
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.createOrderStatus = 'failed'
        state.createOrderError = action.payload as string
      })
  },
})

export const selectOrderBetType = (state: RootState) => state.order.bet
export const selectOrderAmount = (state: RootState) => state.order.amount
const selectOrderIds = (state: RootState) => state.order.myOrderIds
const selectOrderDetails = (state: RootState) => state.order.orderDetails
export const selectMyOrder = (input: SearchOrderQuery) =>
  createSelector([selectOrderIds], (orderIds) => {
    return orderIds[getMyOrderKey(input)] || []
  })
export const selectTop10MyOrders = createSelector([selectGameId, selectOrderIds], (gameId, orderIds) => {
  const key = getMyOrderKey({ gameId })
  const ids = orderIds[key] || []
  return ids.slice(0, 10)
})
export const selectOrderHistoryNoPaging = createSelector([selectTop10MyOrders, selectOrderDetails], (ids, details) => {
  return ids.map((id) => details[id])
})

export const selectCreateOrderStatus = (state: RootState) => state.order.createOrderStatus
export const selectCreateOrderError = (state: RootState) => state.order.createOrderError
const selectTotals = (state: RootState) => state.order.totals
export const selectTotal = createSelector([selectGameId, selectTotals], (gameId, totals) => totals[gameId] || 0)
export const selectOrderDetail = (id: string) => (state: RootState) => selectOrderDetails(state)[id]
export const selectBetAmountByTypes = (gameId: string) =>
  createSelector([selectOrderDetails], (details) => {
    const result = {} as Record<BetType, number>

    forEach(details, (order) => {
      if (order.game_type == gameId) {
        const type = order.bet
        result[type] = result[type] || 0
        result[type] += Number(order.amount)
      }
    })

    return result
  })
export const selectBetAmountByType = (gameId: string) => (type: BetType) =>
  createSelector([selectBetAmountByTypes(gameId)], (details) => {
    return details[type] || 0
  })

export const orderActions = {
  ...orderSlice.actions,
  createOrder,
}

export default orderSlice
