import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import { WalletPlayUser } from '@/redux/store/entities/game.entity'
import { selectUser, selectUserId } from '@/redux/store/modules/auth.slice'
import preferenceSlice from '@/redux/store/modules/preference.slice'
import walletSlice, { getListWallets, getWalletByCurrency, selectWallet } from '@/redux/store/modules/wallet.slice'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function WalletBalanceSubscription() {
  const dispatch = useAppDispatch()
  const wallet = useAppSelector(selectWallet)
  const user = useAppSelector(selectUser)
  const [params] = useSearchParams()

  const currency = params.get('currency')

  useEffect(() => {
    if (currency) {
      dispatch(getWalletByCurrency(currency))
    }
  }, [currency])

  useEffect(() => {
    if (!wallet?.id) {
      dispatch(getListWallets())
      if (!currency && user && user.default_wallet) {
        dispatch(preferenceSlice.actions.activeWalletUpdated(user.default_wallet))
      }
    }
  }, [])

  const userId = useAppSelector(selectUserId) || ''

  const _message = useSubscription(Topics.walletBalance(userId))
  const message = _message?.message?.message

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '') as WalletPlayUser
      dispatch(walletSlice.actions.walletUpdated(data))
    } catch (error) {
      console.warn('WalletBalanceSubscription error: ', error)
    }
  }, [message])

  return <></>
}
